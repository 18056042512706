import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Button, Card, Col, Row, Spinner } from "react-bootstrap";
import Select from "./Select";
import NumberInput from "./NumberInput";
import GamePicker from "./GamePicker";
import { getMultipleGameFeedInfosByIdAsync } from "../utils/api";
import { createTournamentWageringConditionModel } from "../utils/models";
import { platform_options } from "../utils/options";
import { DragDropGames } from "./DragDropGames";

const TournamentWageringConditionList = ({
  name,
  wageringConditions,
  onWageringConditionsChange,
  className,
  disabled,
  errors,
}) => {
  const [state, setState] = useState({
    loading: false,
    gamePickerGames: [],
    gamePickerTargetIndex: -1,
    gameInfos: {},
    error: null,
  });

  const handleAdd = () => {
    if (typeof onWageringConditionsChange !== "function") return;

    const newCondition = createTournamentWageringConditionModel();

    onWageringConditionsChange({
      target: { name, value: [...wageringConditions, newCondition] },
    });
  };

  const handleRemoveEmpty = () => {
    if (typeof onWageringConditionsChange !== "function") return;

    onWageringConditionsChange({
      target: {
        name,
        value: [
          ...wageringConditions.filter(
            (c) => c && c.casino_game_ids.length > 0
          ),
        ],
      },
    });
  };

  const handleDuplication = (index) => {
    if (typeof onWageringConditionsChange !== "function") return;

    const newCondition = {
      ...wageringConditions[index],
      casino_game_ids: [...wageringConditions[index].casino_game_ids],
    };

    onWageringConditionsChange({
      target: { name, value: [...wageringConditions, newCondition] },
    });
  };

  const handleDeletion = (index) => {
    if (typeof onWageringConditionsChange !== "function") return;

    onWageringConditionsChange({
      target: { name, value: wageringConditions.filter((_, i) => i !== index) },
    });
  };

  const showGamePicker = useCallback(
    (index) => {
      setState((prev) => ({
        ...prev,
        gamePickerGames: [...wageringConditions[index].casino_game_ids],
        gamePickerTargetIndex: index,
      }));
    },
    [wageringConditions]
  );

  const closeGamePicker = useCallback(() => {
    setState((prev) => ({
      ...prev,
      gamePickerGames: [],
      gamePickerTargetIndex: -1,
    }));
  }, []);

  const handleGamePickerChanges = useCallback(
    (selectedGames) => {
      if (typeof onWageringConditionsChange !== "function") return;
      if (disabled) return;

      const index = state.gamePickerTargetIndex;

      const value = [...wageringConditions];
      value[index] = {
        ...value[index],
        casino_game_ids: [...selectedGames],
      };

      onWageringConditionsChange({ target: { name, value } });
      closeGamePicker();
    },
    [
      name,
      wageringConditions,
      onWageringConditionsChange,
      disabled,
      closeGamePicker,
      state.gamePickerTargetIndex,
    ]
  );

  const handleGamesChanges = useCallback(
    (index, games) => {
      if (typeof onWageringConditionsChange !== "function") return;
      if (disabled) return;

      const value = [...wageringConditions];
      value[index] = {
        ...value[index],
        casino_game_ids: [...games],
      };

      onWageringConditionsChange({ target: { name, value } });
    },
    [name, wageringConditions, onWageringConditionsChange, disabled]
  );

  const unknownGameIds = useMemo(
    () => [
      ...new Set(
        wageringConditions
          .reduce((arr, c) => [...arr, ...c.casino_game_ids], [])
          .filter((id) => !state.gameInfos[id])
          .sort()
      ),
    ],
    [wageringConditions, state.gameInfos]
  );

  const loadUnknownGameInfos = async (ct) => {
    if (unknownGameIds.length === 0) {
      setState((prev) => ({ ...prev, loading: false }));
      return;
    }

    try {
      const newGameInfos = await getMultipleGameFeedInfosByIdAsync(
        unknownGameIds,
        ct
      );

      setState((prev) => ({
        ...prev,
        loading: false,
        gameInfos: {
          ...prev.gameInfos,
          ...newGameInfos.reduce((obj, g) => ({ ...obj, [g.id]: g }), {}),
        },
      }));
    } catch (error) {
      if (ct?.aborted) return;

      setState((prev) => ({
        ...prev,
        loading: false,
        error: error,
      }));
    }
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));

    const cts = new AbortController();

    loadUnknownGameInfos(cts.signal);

    return () => {
      cts.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unknownGameIds.join(",")]);

  return (
    <Card className={className}>
      <Card.Title className="d-flex p-3 mb-0 border-bottom">
        <div className="flex-grow-1">Wagering conditions</div>

        {state.loading && (
          <div style={{ minWidth: 20 }}>
            <Spinner size="sm" />
          </div>
        )}
      </Card.Title>

      {state.error && (
        <Card.Body className="border-bottom">
          <Alert variant="danger" className="mb-0">
            {state.error.message}
          </Alert>
        </Card.Body>
      )}

      {errors?.[`${name}`] && (
        <div className="d-none form-control is-invalid" />
      )}

      {wageringConditions.length === 0 ? (
        <Card.Body>
          <div className="text-muted">There are no wagering conditions</div>
        </Card.Body>
      ) : (
        <div className="wagering-conditions">
          {wageringConditions.map((condition, index) => (
            <Card.Body key={index} className={index > 0 ? "border-top" : ""}>
              <div className="py-3 mb-3 border-bottom">
                <Button
                  disabled={disabled}
                  size="sm"
                  variant="outline-primary"
                  className="me-2"
                  onClick={() => showGamePicker(index)}
                >
                  Select games
                </Button>

                <Button
                  disabled={disabled}
                  size="sm"
                  variant="outline-primary"
                  className="me-2"
                  onClick={() => handleDuplication(index)}
                >
                  Duplicate
                </Button>

                <Button
                  disabled={disabled}
                  variant="outline-danger"
                  size="sm"
                  className="float-end"
                  onClick={() => handleDeletion(index)}
                >
                  Delete
                </Button>
              </div>

              <Row>
                <Col className="mb-3">
                  <Select
                    label="Platform"
                    name={`${name}.${index}.platform`}
                    value={condition.platform}
                    onChange={onWageringConditionsChange}
                    options={platform_options}
                    disabled={disabled}
                  />
                </Col>

                <Col className="mb-3">
                  <NumberInput
                    label="Min stake"
                    name={`${name}.${index}.min_stake`}
                    value={condition.min_stake}
                    min={0}
                    onChange={onWageringConditionsChange}
                    disabled={disabled}
                    errorMessages={errors?.[`${name}.${index}.min_stake`]}
                  />
                </Col>

                <Col className="mb-3">
                  <NumberInput
                    label="Max stake"
                    name={`${name}.${index}.max_stake`}
                    value={condition.max_stake}
                    min={condition.min_stake}
                    onChange={onWageringConditionsChange}
                    disabled={disabled}
                    errorMessages={errors?.[`${name}.${index}.max_stake`]}
                  />
                </Col>

                <Col className="mb-3">
                  <NumberInput
                    label="Contribution"
                    name={`${name}.${index}.contribution`}
                    value={condition.contribution}
                    min={0}
                    onChange={onWageringConditionsChange}
                    disabled={disabled}
                    errorMessages={errors?.[`${name}.${index}.contribution`]}
                  />
                </Col>
              </Row>

              <div className="mb-3">
                {condition.casino_game_ids.length} games
              </div>

              {errors?.[`${name}.${index}.casino_game_ids`] && (
                <div className="d-none form-control is-invalid" />
              )}

              {condition.casino_game_ids.length > 0 && (
                <Row>
                  <Col>
                    <Card className="bg-secondary-subtle rounded mb-3">
                      <Card.Body className="p-3">
                        <Row
                          className="g-3"
                          xxl={4}
                          lg={3}
                          md={4}
                          sm={3}
                          xs={2}
                        >
                          <DragDropGames
                            gameIds={condition.casino_game_ids}
                            gameInfos={state.gameInfos}
                            onGamesChange={(games) =>
                              handleGamesChanges(index, games)
                            }
                            accessor={`${name}.${index}`}
                            disabled={disabled}
                          />
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </Card.Body>
          ))}
        </div>
      )}

      <Card.Header className="py-3 border-bottom-0 border-top">
        <Button
          disabled={disabled}
          size="sm"
          variant="outline-primary"
          className="me-2"
          onClick={handleAdd}
        >
          Add condition
        </Button>

        <Button
          disabled={disabled}
          size="sm"
          variant="outline-primary"
          className="me-2"
          onClick={handleRemoveEmpty}
        >
          Remove empty
        </Button>

        {wageringConditions.length > 0 && (
          <div className="float-end">
            {wageringConditions.length} conditions
          </div>
        )}
      </Card.Header>

      {state.gamePickerTargetIndex > -1 && (
        <GamePicker
          selectedGames={state.gamePickerGames}
          onClose={closeGamePicker}
          onSaveChanges={handleGamePickerChanges}
          disabled={disabled}
        />
      )}
    </Card>
  );
};

export default TournamentWageringConditionList;
