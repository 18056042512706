import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { authenticateAsync } from "./utils/auth";
import Unauthorized from "./Unauthorized";

const authenticated = await authenticateAsync();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {authenticated ? <App /> : <Unauthorized />}
  </React.StrictMode>
);
