/**
 * A collection of validation function builders for common types.
 */
export const is = {
  string: {
    /**
     * Build validation function that:
     *    Checks if a string is not empty.
     * @param {string} errorMessage - The error message to return if validation fails.
     * @returns {function(string): string | undefined} - The validation function.
     */
    notEmpty: (errorMessage) => (value) =>
      (typeof value !== "string" || value.length < 1) && errorMessage,

    /**
     * Build validation function that:
     *    Checks if a string is of a length greater or equal to a given value.
     * @param {number} length - The length to compare against.
     * @param {string} errorMessage - The error message to return if validation fails.
     * @returns {function(any[]): string | undefined} - The validation function.
     */
    ofSizeGreaterOrEqual: (length, errorMessage) => (value) =>
      (typeof value !== "string" || value.length < length) && errorMessage,
  },

  number: {
    /**
     * Build validation function that:
     *    Checks if a number is less or equal to a given value.
     * @param {number} pivot - The value to compare against.
     * @param {string} errorMessage - The error message to return if validation fails.
     * @returns {function(number): string | undefined} - The validation function.
     */
    lessOrEqual: (pivot, errorMessage) => (number) =>
      (typeof number !== "number" ||
        typeof pivot !== "number" ||
        number > pivot) &&
      errorMessage,

    /**
     * Build validation function that:
     *    Checks if a number is greater or equal to a given value.
     * @param {number} pivot - The value to compare against.
     * @param {string} errorMessage - The error message to return if validation fails.
     * @returns {function(number): string | undefined} - The validation function.
     */
    greaterOrEqual: (pivot, errorMessage) => (number) =>
      (typeof number !== "number" ||
        typeof pivot !== "number" ||
        number < pivot) &&
      errorMessage,
  },

  date: {
    /**
     * Build validation function that:
     *    Checks if a date is before another date.
     * @param {Date} pivot - The pivot date to compare against.
     * @param {string} errorMessage - The error message to return if validation fails.
     * @returns {function(Date): string | undefined} - The validation function.
     */
    before: (pivot, errorMessage) => (date) =>
      date instanceof Date &&
      pivot instanceof Date &&
      date >= pivot &&
      errorMessage,

    /**
     * Build validation function that:
     *    Checks if a date is after another date.
     * @param {Date} pivot - The pivot date to compare against.
     * @param {string} errorMessage - The error message to return if validation fails.
     * @returns {function(Date): string | undefined} - The validation function.
     */
    after: (pivot, errorMessage) => (date) =>
      date instanceof Date &&
      pivot instanceof Date &&
      date <= pivot &&
      errorMessage,

    ISO8601: {
      /**
       * Build validation function that:
       *    Checks if a date is before another date.
       * @param {Date} pivot - The pivot date to compare against.
       * @param {string} errorMessage - The error message to return if validation fails.
       * @returns {function(Date): string | undefined} - The validation function.
       */
      before: (pivot, errorMessage) => (date) =>
        new Date(date) >= new Date(pivot) && errorMessage,

      /**
       * Build validation function that:
       *    Checks if a date is after another date.
       * @param {Date} pivot - The pivot date to compare against.
       * @param {string} errorMessage - The error message to return if validation fails.
       * @returns {function(Date): string | undefined} - The validation function.
       */
      after: (pivot, errorMessage) => (date) =>
        new Date(date) <= new Date(pivot) && errorMessage,
    },
  },

  array: {
    /**
     * Build validation function that:
     *    Checks if an array include a certain value.
     * @param {any} value - The value to check for in the array.
     * @param {string} errorMessage - The error message to return if validation fails.
     * @returns {function(any[]): string | undefined} - The validation function.
     */
    having: (value, errorMessage) => (array) =>
      (!Array.isArray(array) || !array.includes(value)) && errorMessage,

    /**
     * Build validation function that:
     *    Checks if an array is of a size greater or equal to a given value.
     * @param {number} size - The size to compare against.
     * @param {string} errorMessage - The error message to return if validation fails.
     * @returns {function(any[]): string | undefined} - The validation function.
     */
    ofSizeGreaterOrEqual: (size, errorMessage) => (array) =>
      (!Array.isArray(array) || array.length < size) && errorMessage,
  },
};
