import constants from "./constants";
import {
  getObjectProps,
  getTournamentBonusTypeDisplayText,
  getTournamentOptInConditionDisplayText,
  getTournamentScoreDriverDisplayText,
  getWinCriteriaDisplayText,
} from "./helpers";

/**
 * win criterias.
 */
export const win_criteria_options = getObjectProps(constants.WinCriteria).map(
  (prop) => {
    return {
      key: prop.key,
      value: prop.value,
      displayText: getWinCriteriaDisplayText(prop.value),
    };
  }
);

/**
 * Tournament opt-in conditions.
 */
export const opt_in_condition_options = getObjectProps(
  constants.Tournament.OptInCondition
).map((prop) => {
  return {
    key: prop.key,
    value: prop.value,
    displayText: getTournamentOptInConditionDisplayText(prop.value),
  };
});

/**
 * Game platforms.
 */
export const platform_options = [
  { key: "both", value: "both", displayText: "Both" },
  { key: "desktop", value: "desktop", displayText: "Desktop" },
  { key: "mobile", value: "mobile", displayText: "Mobile" },
];

/**
 * Tournament score drivers.
 */
export const score_driver_options = getObjectProps(
  constants.Tournament.ScoreDriver
).map((prop) => {
  return {
    key: prop.key,
    value: prop.value,
    displayText: getTournamentScoreDriverDisplayText(prop.value),
  };
});

/**
 * Tournament bonus types.
 */
export const bonus_type_options = getObjectProps(
  constants.Tournament.BonusType
).map((prop) => {
  return {
    key: prop.key,
    value: prop.value,
    displayText: getTournamentBonusTypeDisplayText(prop.value),
  };
});
