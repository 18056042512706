const constants = {
  DateTimeStringFormat: "DD MMM YYYY, HH:mm",

  DefaultCurrency: "lei",

  WinCriteria: {
    /**
     * Total Turnover.
     * -----
     * Amount of money played on qualifying bets.
     * Score = Qualifying Bets Turnover x 1.
     */
    Turnover: 1,

    /**
     * Biggest Net Win.
     * -----
     * Single biggest win on a qualifying bet.
     * Score = (Win – Stake) x 100.
     * Example: (200 Win – 100 Stake) x 100 = 10 000 points.
     */
    NetWin: 2,

    /**
     * Biggest Equalized Win.
     * -----
     * Best pay‐out to stake ratio over a single round (bet).
     * Size of the stake is unimportant in this type of tournament.
     * Score = (Win / Stake) x 100. Example 1: 120 Win / 100 Stake x 100 = 120 points.
     * Example 2: 20 Win / 5 Stake x 100 = 400 point.
     */
    EqualizedWin: 3,

    /**
     * Total Number of Rounds.
     * -----
     * Total number of rounds played on qualifying bets.
     * Example: 100 qualifying bets will generate 100 points.
     */
    NumberRounds: 4,
  },

  Tournament: {
    Status: {
      /**
       * Tournament is in draft mode.
       */
      Draft: 0,

      /**
       * Tournament is in progress (scheduled or running).
       */
      InProgress: 1,

      /**
       * Tournament is completed.
       */
      Completed: 2,

      /**
       * Tournament is canceled.
       */
      Canceled: 3,
    },

    ScoreDriver: {
      /**
       * User score is calculated based on gaming transactions.
       */
      GamingTransactions: 0,

      /**
       * User score is calculated based on bonus win amount.
       */
      BonusWinAmount: 1,
    },

    OptInCondition: {
      /**
       * Open.
       * -----
       * Available and visible to all players,
       * all qualifying bets are taken into account and generating points.
       */
      Open: 1,

      /**
       * Opt-In Required.
       * -----
       * Players must opt‐in to participate in the tournament,
       * by clicking on the CTA button of the tournament card.
       */
      OptIn: 2,

      /**
       * Invite Only.
       * -----
       * Ability to upload a CSV file with customer IDs
       * that are eligible for participating.
       */
      Invite: 3,
    },

    BonusType: {
      /**
       * Casino Wagering.
       * -----
       * The winners get awarded a regular casino bonus.
       */
      CasinoWagering: 1,

      /**
       * Free Spins.
       * -----
       * The winners get awarded a number of free spins.
       */
      FreeSpins: 2,

      /**
       * Bonus Money.
       * -----
       * The winners get awarded an amount to be transferred to their wallet.
       */
      BonusMoney: 3,

      /**
       * Items.
       * -----
       * The winners will get awarded items.
       */
      Items: 4,

      /**
       * Free Bet.
       * -----
       * The winners get awarded free bets.
       */
      FreeBet: 5,
    },
  },
};

export default constants;
