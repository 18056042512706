import React, { useEffect, useRef } from "react";
import { Toast as BootstrapToast } from "react-bootstrap";

export default function Toast({ header, text, onClose, delay = 3000 }) {
  const timerRef = useRef(null);

  useEffect(() => {
    if (typeof onClose !== "function") return;

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(onClose, delay);

    return () => clearTimeout(timerRef.current);
  }, [delay, onClose]);

  return (
    <BootstrapToast bg="second" onClose={onClose} autohide>
      <BootstrapToast.Header>
        <strong className="me-auto">{header}</strong>
      </BootstrapToast.Header>

      <BootstrapToast.Body>{text}</BootstrapToast.Body>
    </BootstrapToast>
  );
}
