import React, { useCallback, useEffect, useState } from "react";
import Tournament from "../../components/Tournament";
import {
  performTournamentModelChange,
  prepareTournamentModelForApi,
} from "../../utils/models";
import { Alert, Button, Container, Navbar, Spinner } from "react-bootstrap";
import {
  assignPlayerAsync,
  cancelTournamentAsync,
  createTournamentAsync,
  getTournamentAsync,
  updateTournamentAsync,
} from "../../utils/api";
import { useParams } from "react-router-dom";
import constants from "../../utils/constants";
import {
  getTournamentStatusDisplayText,
  isNullOrWhiteSpace,
} from "../../utils/helpers";
import Tabs from "../../components/Tabs";
import Leaderboard from "./Leaderboard";
import BonusOutbox from "./BonusOutbox";
import { tournamentUpdateValidator } from "../../validations/tournamentUpdateValidator";
import { tournamentSaveValidator } from "../../validations/tournamentSaveValidator";
import AssignPlayerModal from "../../components/AssignPlayerModal";

const TournamentPage = ({ setTitle, setToasts }) => {
  const { id } = useParams();

  const [refresh, setRefresh] = useState(0);
  const [tournament, setTournament] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showAssignPlayerModal, setShowAssignPlayerModal] = useState(false);

  setTitle(tournament?.title || "Loading...");

  const [state, setState] = useState({
    loading: false,
    error: null,
    editable: false,
  });

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true, error: null }));

    const cts = new AbortController();
    loadTournament(id, cts.signal);

    return () => {
      cts.abort();
    };
  }, [id, refresh]);

  const loadTournament = async (id, ct) => {
    try {
      const tournament = await getTournamentAsync(id, ct);
      setTournament(tournament);

      setState((prev) => ({
        ...prev,
        loading: false,
        editable: tournament.status === constants.Tournament.Status.Draft,
      }));
    } catch (error) {
      if (ct?.aborted) return;

      setTournament(null);
      setState((prev) => ({
        ...prev,
        loading: false,
        error: error,
      }));
    }
  };

  const handleTournamentChange = useCallback((e) => {
    setTournament((prev) => {
      const tournament = { ...prev };
      performTournamentModelChange(tournament, e);
      setHasUnsavedChanges(true);

      const validator = new tournamentUpdateValidator(tournament);
      setValidationErrors((errors) =>
        validator.validate(e.target.name, errors)
      );

      return tournament;
    });
  }, []);

  const handleSaveTournament = () => {
    const validator = new tournamentSaveValidator(tournament);
    const errors = validator.validate();

    const toasts = [];
    for (let key in errors) {
      for (let error of errors[key]) {
        toasts.push({ header: "Error", text: error });
      }
    }

    if (toasts.length > 0) {
      setToasts(toasts);
      setValidationErrors(errors);
      return;
    }

    setState((prev) => ({ ...prev, loading: true, error: null }));
    prepareTournamentModelForApi(tournament);
    saveTournament(tournament);
  };

  const saveTournament = async (tournament) => {
    try {
      const updated = await updateTournamentAsync(tournament);

      if (updated) {
        setRefresh((prev) => prev + 1);
        setToasts([{ header: "Success", text: "Tournament saved" }]);
        setHasUnsavedChanges(false);
        return;
      }

      setState((prev) => ({
        ...prev,
        error: "Failed to save tournament",
        loading: false,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: error,
      }));
    }
  };

  const handlePublishTournament = () => {
    if (hasUnsavedChanges) {
      setToasts([{ header: "Error", text: "You have unsaved changes" }]);
      return;
    }

    if (tournament.title?.trim().toLowerCase().endsWith("[clone]")) {
      setToasts([
        {
          header: "Error",
          text: "Please change the title. It must not end with [Clone]",
        },
      ]);
      return;
    }

    setState((prev) => ({ ...prev, loading: true, error: null }));

    prepareTournamentModelForApi(tournament);

    tournament.status = constants.Tournament.Status.InProgress;

    saveTournament(tournament);
  };

  const handleCancelTournament = async () => {
    const cancel = window.confirm(
      "Are you sure you want to cancel the tournament?"
    );

    if (!cancel) return;

    setState((prev) => ({ ...prev, loading: true, error: null }));

    await cancelTournamentAsync(tournament.id);

    setRefresh((prev) => prev + 1);
  };

  const handleDuplicateTournament = async () => {
    setState((prev) => ({ ...prev, loading: true, error: null }));

    let id = await createTournamentAsync({
      ...tournament,
      status: constants.Tournament.Status.Draft,
      title: `${tournament.title} [Clone]`,
    });

    setRefresh((prev) => prev + 1);

    window.open("/tournament/" + id, "_blank");
  };

  const handleAssignPlayer = async (userId) => {
    setState((prev) => ({ ...prev, loading: true, error: null }));

    try {
      const resp = await assignPlayerAsync(tournament.id, userId);

      // if the player does not exist or is already assigned
      // the response will be `false`
      if (!resp) {
        throw new Error(
          `Failed to assign player '${userId}'. Player not found or already assigned.`
        );
      }

      setState((prev) => ({ ...prev, loading: false, error: null }));
      setShowAssignPlayerModal(false);

      setToasts([{ header: "Success", text: "Player assigned" }]);
    } catch (ex) {
      setState((prev) => ({ ...prev, loading: false, error: ex }));
      setShowAssignPlayerModal(false);
    }
  };

  const isEditable = state.editable && !state.loading;
  const title = state.loading
    ? "Loading..."
    : (tournament &&
        `${getTournamentStatusDisplayText(tournament.status)} tournament` +
          (!isNullOrWhiteSpace(tournament.title)
            ? ` - ${tournament.title}`
            : "")) ||
      "Not found";

  const disableLeaderboard =
    !tournament || tournament.status === constants.Tournament.Status.Draft;

  const disableBonuses =
    !tournament || tournament.status !== constants.Tournament.Status.Completed;

  const showAssignPlayerButton =
    tournament?.status === constants.Tournament.Status.InProgress &&
    tournament.opt_in_condition === constants.Tournament.OptInCondition.OptIn;

  return (
    <React.Fragment>
      <div className="tournament-page" style={{ marginBottom: 200 }}>
        <div className="d-flex border-bottom mb-3">
          <div className="page-title flex-grow-1 fs-5">
            {title}

            {tournament?.status === constants.Tournament.Status.InProgress && (
              <Button
                className="float-end px-4 ms-2"
                size="sm"
                variant="danger"
                onClick={handleCancelTournament}
                disabled={state.loading}
              >
                Cancel
              </Button>
            )}

            {showAssignPlayerButton && (
              <Button
                className="float-end px-4 ms-2"
                size="sm"
                variant="primary"
                onClick={() => setShowAssignPlayerModal(true)}
                disabled={state.loading}
              >
                Assign player
              </Button>
            )}

            {tournament && isEditable && (
              <Button
                className="float-end px-4 ms-2"
                size="sm"
                variant="success"
                onClick={handlePublishTournament}
                disabled={state.loading}
              >
                Publish
              </Button>
            )}

            <Button
              className="float-end px-4 ms-2"
              size="sm"
              variant="primary"
              onClick={handleDuplicateTournament}
              disabled={state.loading}
            >
              Duplicate
            </Button>
          </div>
        </div>

        {state.error && <Alert variant="danger">{state.error.message}</Alert>}

        <Tabs
          tabs={[
            {
              key: "",
              title: "Tournament",
              content: tournament && (
                <Tournament
                  tournament={tournament}
                  onTournamentChange={handleTournamentChange}
                  disabled={!isEditable}
                  errors={validationErrors}
                />
              ),
            },
            {
              key: "leaderboard",
              title: "Leaderboard",
              disabled: disableLeaderboard,
              content: <Leaderboard tournament={tournament} />,
            },
            {
              key: "bonus-outbox",
              title: "Bonus Outbox",
              disabled: disableBonuses,
              content: <BonusOutbox tournament={tournament} />,
            },
          ]}
        />
      </div>

      {state.editable && (
        <Navbar className="fixed-bottom bg-body-tertiary border-top p-3">
          <Container fluid>
            <Button
              className="px-4"
              variant="primary"
              size="sm"
              onClick={handleSaveTournament}
              disabled={state.loading}
            >
              Save
            </Button>

            {state.loading && <Spinner size="sm" />}
          </Container>
        </Navbar>
      )}
      {showAssignPlayerModal && (
        <AssignPlayerModal
          onAssignPlayer={handleAssignPlayer}
          onClose={() => setShowAssignPlayerModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default TournamentPage;
