import { Button, Modal } from "react-bootstrap";
import Input from "./Input";
import { useState } from "react";

const AssignPlayerModal = ({ onAssignPlayer, onClose }) => {
  const [value, setValue] = useState("");

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Assign player</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          className="mb-3"
          label="Player ID"
          type="text"
          name="playerId"
          placeholder="Enter player id..."
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => onAssignPlayer(value)}>
          Assign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignPlayerModal;
