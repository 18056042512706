import { Button, Card, Col, Row } from "react-bootstrap";
import Input from "./Input";
import NumberInput from "./NumberInput";
import { setProp } from "../utils/helpers";
import { useCallback, useMemo } from "react";

const TournamentBonusWinAmountIngestionConfiguration = ({
  name,
  config,
  onConfigChange,
  className,
  disabled,
  errors,
}) => {
  const count = useMemo(() => Object.keys(config).length, [config]);

  const handleAdd = useCallback(() => {
    if (typeof onConfigChange !== "function") return;
    if (config[""]) return;

    const newConfig = { ...config };
    newConfig[""] = {
      win_amount_multiplication_factor: 1,

      //
      // This is an internal field and is not consumed by the server,
      // therefore it will be lost once submitted.
      $__index:
        /* next index -> */ Math.max(
          Math.max(...Object.values(config).map((v) => v.$__index)),
          count - 1
        ) + 1,
    };

    // update state
    onConfigChange({ target: { name, value: newConfig } });
  }, [config, onConfigChange, name, count]);

  const handleDeletion = useCallback(
    (key) => {
      if (typeof onConfigChange !== "function") return;

      const newConfig = { ...config };
      delete newConfig[key];

      // update state
      onConfigChange({ target: { name, value: newConfig } });
    },
    [config, onConfigChange, name]
  );

  const handleKeyChange = useCallback(
    (oldKey, newKey, index) => {
      if (typeof onConfigChange !== "function") return;
      if (newKey === oldKey) return;

      const newConfig = { ...config };
      newConfig[newKey] = newConfig[oldKey];
      delete newConfig[oldKey];

      newConfig[newKey].$__index ??= index; // materialize index

      // update state
      onConfigChange({ target: { name, value: newConfig } });
    },
    [config, onConfigChange, name]
  );

  const handleEdit = useCallback(
    (e, index) => {
      if (typeof onConfigChange !== "function") return;

      const newConfig = { ...config };
      const path = e.target.name.split(".");
      setProp(
        newConfig,
        [...path].splice(1).join("."), // remove name
        e.target.value
      );

      newConfig[path[1] /* key */].$__index ??= index; // materialize index

      // update state
      onConfigChange({ target: { name, value: newConfig } });
    },
    [config, onConfigChange, name]
  );

  return (
    <Card className={className}>
      <Card.Title className="p-3 mb-0 border-bottom">
        Bonus win amount ingestion
      </Card.Title>

      {count === 0 ? (
        <Card.Body>
          <div className="text-muted">There are no bonus listeners</div>
        </Card.Body>
      ) : (
        <Card.Body>
          {Object.entries(config)
            .sort(([, a], [, b]) => a.$__index - b.$__index)
            .map(([key, listener], index) => (
              <Row key={listener.$__index ?? index} className="mb-2">
                <Col xs={6}>
                  <Input
                    label="Bonus Id"
                    name={`${name}.${key}`}
                    defaultValue={key}
                    onBlur={(e) => handleKeyChange(key, e.target.value, index)}
                    onKeyDown={(e) => {
                      // prevent space from being typed
                      if (e.keyCode === 32) e.preventDefault();
                    }}
                    onPaste={(e) => {
                      // remove spaces
                      e.target.value = e.clipboardData
                        .getData("text")
                        .replace(/\s/g, "");

                      e.preventDefault();
                    }}
                    placeholder="..."
                    disabled={disabled}
                    errorMessages={errors?.[`${name}.${key}`]}
                  />
                </Col>

                <Col xs={4}>
                  <NumberInput
                    label="Multiplication factor"
                    name={`${name}.${key}.win_amount_multiplication_factor`}
                    value={listener.win_amount_multiplication_factor}
                    onChange={(e) => handleEdit(e, index)}
                    min={1}
                    disabled={disabled}
                    errorMessages={
                      errors?.[
                        `${name}.${key}.win_amount_multiplication_factor`
                      ]
                    }
                  />
                </Col>

                <Col xs={2} style={{ paddingTop: "35px" }}>
                  <Button
                    disabled={disabled}
                    variant="outline-danger"
                    size="sm"
                    className="float-end"
                    onClick={() => handleDeletion(key)}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            ))}
        </Card.Body>
      )}

      <Card.Header className="py-3 border-bottom-0 border-top">
        <Button
          disabled={disabled}
          size="sm"
          variant="outline-primary"
          className="me-2"
          onClick={handleAdd}
        >
          Add listener
        </Button>

        <Button
          disabled={disabled}
          size="sm"
          variant="outline-primary"
          className="me-2"
          onClick={() => handleDeletion("")}
        >
          Remove empty
        </Button>

        {count > 0 && <div className="float-end">{count} listeners</div>}
      </Card.Header>
    </Card>
  );
};

export default TournamentBonusWinAmountIngestionConfiguration;
