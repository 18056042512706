import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DragDropItem } from "./DragDropItem";

export const DragDropList = ({ items, onItemMove, accessor, disabled }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      {items.map((item, index) => (
        <DragDropItem
          key={index}
          accessor={accessor}
          index={index}
          item={item}
          onItemMove={(from, to) => onItemMove(from, to)}
          disabled={disabled}
        />
      ))}
    </DndProvider>
  );
};
