import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Input from "./Input";
import { Form } from "react-bootstrap";
import { isNullOrWhiteSpace, isImageAccessible } from "../utils/helpers";

const fetch_delay = 1000;

const status_enum = {
  not_set: 0,
  accessible: 1,
  not_accessible: 2,
  loading: 3,
};

const ImageSourceInput = memo(function ImageSourceInput({
  className,
  label,
  name,
  value,
  onChange,
  placeholder,
  disabled,
  errorMessages,
}) {
  const fetchTimerRef = useRef(null);

  const [status, setStatus] = useState(status_enum.not_set);

  const updateStatus = useCallback((src) => {
    isImageAccessible(src).then((accessible) =>
      setStatus(
        accessible ? status_enum.accessible : status_enum.not_accessible
      )
    );
  }, []);

  const handleChange = useCallback(
    (e) => {
      if (typeof onChange === "function") onChange(e);

      const src = e.target.value;
      if (fetchTimerRef.current) clearTimeout(fetchTimerRef.current);

      if (isNullOrWhiteSpace(src)) {
        setStatus(status_enum.not_set);
        return;
      }

      setStatus(status_enum.loading);
      fetchTimerRef.current = setTimeout(() => updateStatus(src), fetch_delay);
    },
    [onChange, updateStatus]
  );

  useEffect(() => {
    if (isNullOrWhiteSpace(value)) return;

    setStatus(status_enum.loading);
    updateStatus(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { statusClassName, statusText } = useMemo(() => {
    let statusClassName = "";
    let statusText = "";

    switch (status) {
      case status_enum.accessible:
        statusClassName = "text-success";
        statusText = "accessible";
        break;

      case status_enum.not_accessible:
        statusClassName = "text-danger";
        statusText = "not accessible";
        break;

      case status_enum.not_set:
        statusClassName = "text-muted";
        statusText = "not set";
        break;

      case status_enum.loading:
        statusClassName = "text-muted";
        statusText = "loading...";
        break;

      default:
        break;
    }

    return { statusClassName, statusText };
  }, [status]);

  return (
    <React.Fragment>
      <Input
        className={className}
        label={label}
        type="text"
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        errorMessages={errorMessages}
      />
      <Form.Text className={statusClassName}>{statusText}</Form.Text>
    </React.Fragment>
  );
});

export default ImageSourceInput;
