import React from "react";
import TournamentCardPlaceholder from "./TournamentCardPlaceholder";
import TournamentCard from "./TournamentCard";
import { isArrayWithAtLeastOneElement } from "../utils/helpers";
import { Alert } from "react-bootstrap";

const TournamentCardGrid = ({ loading, tournaments }) => {
  let body = (
    <Alert variant="info" className="flex-grow-1">
      No tournaments found
    </Alert>
  );

  if (loading) {
    body = Array.from({ length: 3 }).map((_, idx) => (
      <TournamentCardPlaceholder key={idx} />
    ));
  } else if (isArrayWithAtLeastOneElement(tournaments)) {
    body = tournaments.map((t) => <TournamentCard key={t.id} tournament={t} />);
  }

  return <div className="tournaments d-flex flex-wrap">{body}</div>;
};

export default TournamentCardGrid;
