import { memo } from "react";
import { Pagination as InnerPagination } from "react-bootstrap";

const Pagination = memo(function Pagination({
  className,
  page,
  maxPage,
  onPageClick,
  margin = 3,
}) {
  const handlePageClick = (clickedPage) => {
    if (typeof onPageClick !== "function") return;
    if (clickedPage < 0 || clickedPage > maxPage || clickedPage === page)
      return;

    onPageClick(clickedPage);
  };

  const minPage = 0;

  const prevPage = page - 1;
  const disablePrev = page <= minPage;

  const nextPage = page + 1;
  const disableNext = page >= maxPage;

  const bounds = { start: minPage, end: maxPage };

  if (page < margin) {
    //
    // [{1}, 2, 3, 4, 5] -> active page is on the left side of the margin
    bounds.end = Math.min(margin * 2, maxPage);
  } else if (page <= maxPage - margin) {
    //
    // [3, 4, {5}, 6, 7] -> active page is at the center
    bounds.start = Math.max(minPage, page - margin);
    bounds.end = Math.min(page + margin, maxPage);
  } else {
    //
    // [5, 6, 7, 8, {9}] -> active page is on the righ side of the margin
    bounds.start = Math.max(minPage, maxPage - margin * 2);
  }

  const pages = [];
  for (let i = bounds.start; i <= bounds.end; i++) {
    pages.push(i);
  }

  return (
    <InnerPagination size="sm" className={className}>
      <InnerPagination.First
        onClick={() => handlePageClick(minPage)}
        disabled={disablePrev}
      >
        First
      </InnerPagination.First>

      <InnerPagination.Prev
        onClick={() => handlePageClick(prevPage)}
        disabled={disablePrev}
      >
        Prev
      </InnerPagination.Prev>

      {pages.map((p) => (
        <InnerPagination.Item
          key={p}
          active={p === page}
          onClick={() => handlePageClick(p)}
        >
          {p + 1}
        </InnerPagination.Item>
      ))}

      <InnerPagination.Next
        onClick={() => handlePageClick(nextPage)}
        disabled={disableNext}
      >
        Next
      </InnerPagination.Next>

      <InnerPagination.Last
        onClick={() => handlePageClick(maxPage)}
        disabled={disableNext}
      >
        Last
      </InnerPagination.Last>
    </InnerPagination>
  );
});

export default Pagination;
