import { modelValidator } from "./modelValidator";
import { is } from "./is";
import { isNullOrWhiteSpace } from "../utils/helpers";

export class tournamentUpdateValidator extends modelValidator {
  _configureSchema(schema, tournament) {
    super._configureSchema(schema, tournament);

    schema["title"] = [is.string.notEmpty("Title required")];
    schema["hero_image_desktop"] = [
      is.string.notEmpty("Desktop image required"),
    ];

    schema["hero_image_mobile"] = [is.string.notEmpty("Mobile image required")];
    schema["list_image"] = [is.string.notEmpty("List image required")];

    schema["cta_text"] = [is.string.notEmpty("CTA required")];

    if (isNullOrWhiteSpace(tournament.terms_and_conditions))
      schema["terms_and_conditions_text"] = [
        is.string.notEmpty("T&C text is required if no url provided"),
      ];

    schema["time_configuration.start"] = [
      is.string.notEmpty("Start date required"),
      is.date.ISO8601.before(
        tournament.time_configuration.end,
        "Tournament duration is too short"
      ),
    ];

    schema["time_configuration.end"] = [
      is.string.notEmpty("End date required"),
    ];

    schema["time_configuration.day"] = [
      is.array.having(true, "At least one day of week should be available"),
    ];

    tournament.wagering_conditions.forEach((condition, i) => {
      const key = `wagering_conditions.${i}`;

      schema[`${key}.min_stake`] = [
        is.number.greaterOrEqual(1, "Min stake should be greater than 0"),
      ];

      //
      // Max stake can be null, so we need to check if it is a number before comparing it to the min stake.
      //  (typeof value === "number") === false when max stake is null.
      schema[`${key}.max_stake`] = [
        (maxStake) =>
          typeof maxStake === "number" &&
          maxStake < condition.min_stake &&
          "Max stake should be greater than min stake",
      ];

      schema[`${key}.contribution`] = [
        is.number.greaterOrEqual(1, "Contribution should be greater than 0"),
      ];
    });

    tournament.tiers.forEach((tier, i) => {
      const key = `tiers.${i}`;

      schema[`${key}.rank_min`] = [
        is.number.greaterOrEqual(1, "Min rank should be greater than 0"),
      ];

      schema[`${key}.rank_max`] = [
        is.number.greaterOrEqual(1, "Max rank should be greater than 0"),
        is.number.greaterOrEqual(
          tier.rank_min,
          "Max rank should be greater than min rank"
        ),
      ];

      schema[`${key}.bonus.code`] = [is.string.notEmpty("Bonus code required")];
      schema[`${key}.bonus.name`] = [is.string.notEmpty("Bonus name required")];
    });
  }

  _getValue(key) {
    const tournament = this._model;

    //
    // This property does not exist in the model (virtual).
    // It is handled in src/utils/models.js, performTournamentModelChange function.
    if (key === "time_configuration.day") {
      const tc = tournament.time_configuration;
      return [
        tc.monday,
        tc.tuesday,
        tc.wednesday,
        tc.thursday,
        tc.friday,
        tc.saturday,
        tc.sunday,
      ];
    }

    return super._getValue(key);
  }
}
