import { memo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import BonusPicker from "./BonusPicker";
import { List } from "react-bootstrap-icons";
import "./BonusCodeInput.css";

const BonusCodeInput = memo(function BonusCodeInput({
  className,
  label,
  name,
  value,
  onChange,
  placeholder,
  disabled,
  errorMessages,
  bonuses,
}) {
  const [showPicker, setShowPicker] = useState(false);

  const handleCodeChange = (event) => {
    if (typeof onChange !== "function") return;

    onChange({ target: { name: `${name}.code`, value: event.target.value } });
  };

  const handleSelect = (bonus) => {
    if (typeof onChange !== "function") return;

    onChange({ target: { name, value: { ...bonus } } });
    closeBonusPicker();
  };

  const openBonusPicker = () => {
    setShowPicker(true);
  };

  const closeBonusPicker = () => {
    setShowPicker(false);
  };

  const isInvalid = Array.isArray(errorMessages) && errorMessages.length > 0;
  const lowerCaseValue = value.toLowerCase();
  const isValid = bonuses.find(
    (item) => item.code.toLowerCase() === lowerCaseValue
  );

  return (
    <Form.Group className={className}>
      <Form.Label>{label}</Form.Label>
      <InputGroup className={isInvalid && "is-invalid"}>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={handleCodeChange}
          placeholder={placeholder}
          disabled={disabled}
          isInvalid={isInvalid}
          isValid={isValid}
        />

        {!disabled && (
          <Button
            variant="outline-secondary"
            className="bonus-picker-button"
            onClick={openBonusPicker}
          >
            <List />
          </Button>
        )}
      </InputGroup>

      {isInvalid && (
        <Form.Text className="invalid-feedback">{errorMessages[0]}</Form.Text>
      )}

      {showPicker && (
        <BonusPicker
          disabled={disabled}
          bonuses={bonuses}
          onSelect={handleSelect}
          onClose={closeBonusPicker}
        />
      )}
    </Form.Group>
  );
});

export default BonusCodeInput;
