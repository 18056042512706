import React, { memo, useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import {
  getGameFeedInfosAsync,
  getMultipleGameFeedInfosByIdAsync,
  searchGameFeedInfosAsync,
} from "../utils/api";
import { FixedSizeList } from "react-window";
import "./GamePicker.css";
import { isNullOrWhiteSpace } from "../utils/helpers";

const page_size = 500;
const search_delay = 1000;

const GamePicker = memo(function GamePicker({
  selectedGames,
  onClose,
  onSaveChanges,
  disabled,
}) {
  const searchRef = useRef(null);
  const searchTimerRef = useRef(null);
  const selectedGamesOnlyRef = useRef(null);
  const gameListRef = useRef(null);
  const ctsRef = useRef(null);

  const [state, setState] = useState({
    loading: false,
    games: [],
    selectedGames:
      selectedGames?.reduce((map, id, _) => {
        map[id] = true;
        return map;
      }, {}) ?? {},
    error: null,
    page: 0,
    showLoadMoreButton: true,
  });

  const handleClose = () => {
    if (typeof onClose === "function") onClose();
  };

  const handleSaveChanges = () => {
    if (typeof onSaveChanges === "function") {
      const ids = getSelectedGameIds();
      onSaveChanges(ids);
    }
  };

  const handleSearch = (e) => {
    if (selectedGamesOnlyRef.current?.checked) return;

    ctsRef.current?.abort();
    ctsRef.current = new AbortController();

    const searchString = e.target.value;
    if (searchTimerRef.current) clearTimeout(searchTimerRef.current);

    searchTimerRef.current = setTimeout(() => {
      setState((prev) => ({ ...prev, loading: true, error: null }));

      if (!isNullOrWhiteSpace(searchString)) {
        searchGames(searchString, ctsRef.current.signal);
      } else {
        loadPage(ctsRef.current.signal);
      }
    }, search_delay);
  };

  const handleLoadPage = () => {
    ctsRef.current?.abort();
    ctsRef.current = new AbortController();

    setState((prev) => ({ ...prev, loading: true, error: null }));
    loadPage(ctsRef.current.signal);
  };

  const handleShowSelectedGamesOnly = (e) => {
    ctsRef.current?.abort();
    ctsRef.current = new AbortController();

    if (e.target.checked) {
      loadSelectedGamesOnly(ctsRef.current.signal);
      return;
    }

    const searchString = searchRef.current.value;
    if (!isNullOrWhiteSpace(searchString)) {
      searchGames(searchString, ctsRef.current.signal);
      return;
    } else {
      loadPage(ctsRef.current.signal);
      return;
    }
  };

  const handleSelectGame = (id, checked) => {
    setState((prev) => ({
      ...prev,
      selectedGames: {
        ...prev.selectedGames,
        [id]: checked,
      },
    }));
  };

  const searchGames = async (searchString, ct) => {
    try {
      const games = await searchGameFeedInfosAsync(searchString, ct);

      setState((prev) => ({
        ...prev,
        loading: false,
        games: [...games],
        page: 0,
        showLoadMoreButton: false,
        scrollOffset: 0,
      }));
    } catch (error) {
      if (ct?.aborted) return;

      setState((prev) => ({
        ...prev,
        loading: false,
        error: error,
      }));
    }
  };

  const getSelectedGameIds = () => {
    const ids = [];
    for (const id in state.selectedGames) {
      if (state.selectedGames[id]) ids.push(parseFloat(id));
    }

    return ids;
  };

  const loadSelectedGamesOnly = async (ct) => {
    try {
      const ids = getSelectedGameIds();
      const games = await getMultipleGameFeedInfosByIdAsync(ids, ct);

      setState((prev) => ({
        ...prev,
        loading: false,
        games: [...games],
        page: 0,
        showLoadMoreButton: false,
        scrollOffset: 0,
      }));
    } catch (error) {
      if (ct?.aborted) return;

      setState((prev) => ({
        ...prev,
        loading: false,
        error: error,
      }));
    }
  };

  const loadPage = async (ct) => {
    try {
      const scrollOffset = gameListRef.current?.state?.scrollOffset ?? 0;
      const games = await getGameFeedInfosAsync(
        page_size,
        state.page * page_size,
        ct
      );

      setState((prev) => ({
        ...prev,
        loading: false,
        games: prev.page === 0 ? [...games] : [...prev.games, ...games],
        page: prev.page + 1,
        showLoadMoreButton: games.length === page_size,
        scrollOffset,
      }));
    } catch (error) {
      if (ct?.aborted) return;

      setState((prev) => ({
        ...prev,
        loading: false,
        error: error,
      }));
    }
  };

  useEffect(() => {
    handleLoadPage();

    return () => {
      ctsRef.current?.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show onHide={handleClose} className="game-picker">
      <Modal.Header closeButton>
        <Modal.Title>Game Picker</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Control
          type="text"
          placeholder="Search..."
          className="mb-2"
          onChange={handleSearch}
          ref={searchRef}
        />

        <Form.Check
          label="Show only selected games"
          onChange={handleShowSelectedGamesOnly}
          ref={selectedGamesOnlyRef}
        />
      </Modal.Body>

      <div className="border-top games">
        {state.error ? (
          <Modal.Body>
            <Alert variant="danger" className="mb-0">
              {state.error}
            </Alert>
          </Modal.Body>
        ) : state.loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "300px" }}
          >
            <Spinner />
          </div>
        ) : state.games.length === 0 ? (
          <center className="my-4">No games found</center>
        ) : (
          <FixedSizeList
            itemCount={state.games.length}
            itemSize={110}
            height={300}
            ref={gameListRef}
            initialScrollOffset={state.scrollOffset}
          >
            {({ index, style }) => {
              const game = state.games[index];
              const selected = !!state.selectedGames[game.id];

              return (
                <div
                  style={style}
                  className={`game d-flex flex-row py-2 ${
                    selected ? "selected" : ""
                  }`}
                  key={game.id}
                >
                  <div
                    className="game-thumbnail align-self-center mx-3"
                    style={{ minWidth: 80 }}
                  >
                    <img
                      src={game.thumbnail}
                      alt={game.game_name}
                      height={80}
                    />
                  </div>

                  <div className="flex-grow-1 align-self-center">
                    <div className="d-grid">
                      <div className="text-muted">{game.id}</div>
                      <div className="text-truncate">{game.game_name}</div>
                      <div className="text-truncate text-muted">
                        {game.vendor}
                      </div>
                    </div>
                  </div>

                  <div
                    className="align-self-center p-2"
                    style={{ minWidth: 32 }}
                  >
                    <Form.Check
                      type="checkbox"
                      checked={selected}
                      onChange={(e) =>
                        handleSelectGame(game.id, e.target.checked)
                      }
                      disabled={disabled}
                    />
                  </div>
                </div>
              );
            }}
          </FixedSizeList>
        )}
      </div>

      {state.showLoadMoreButton && (
        <Modal.Footer>
          <div className="flex-grow-1">{state.games.length} games loaded</div>
          <Button variant="secondary" onClick={handleLoadPage}>
            Load More
          </Button>
        </Modal.Footer>
      )}

      <Modal.Footer>
        <div className="flex-grow-1">
          {Object.keys(state.selectedGames).length} games selected
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {!disabled && (
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
});

export default GamePicker;
