import { memo } from "react";
import { Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import "./RichTextarea.css";

const tinymce_script_src = `${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`;

const RichTextarea = memo(function RichTextarea({
  className,
  label,
  name,
  value,
  onChange,
  disabled,
  height,
  errorMessages,
}) {
  const isInvalid = Array.isArray(errorMessages) && errorMessages.length > 0;

  const editorOptions = {
    skin: "oxide-dark",
    content_css: "dark",
    content_style: `
      @media print {
        body {
          background-color: #fff;
          color: #000;
        }
      }
    `,

    promotion: false,
    branding: false,
    plugins: [
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "preview",
      "help",
      "wordcount",
    ],

    menubar: !disabled,
    toolbar: !disabled
      ? "undo redo | blocks | bold italic underline forecolor backcolor removeformat | " +
        "bullist numlist | align outdent indent hr | link image media"
      : false,
    statusbar: !disabled,
    help_accessibility: false,

    height: !disabled ? Math.max(300, height) : height,
  };

  const editorStyle = {
    backgroundColor: "#222F3E",
    overflow: "hidden",
  };

  const editorClassNames = ["border", "rounded"];
  if (isInvalid) editorClassNames.push("border-invalid", "is-invalid");

  return (
    <Form.Group className={className}>
      <Form.Label>{label}</Form.Label>
      <div className={editorClassNames.join(" ")} style={editorStyle}>
        <Editor
          tinymceScriptSrc={tinymce_script_src}
          init={editorOptions}
          disabled={disabled}
          value={value}
          onEditorChange={(content, _) =>
            onChange({ target: { name, value: content } })
          }
        />
      </div>
      {isInvalid && (
        <Form.Text className="invalid-feedback">{errorMessages[0]}</Form.Text>
      )}
    </Form.Group>
  );
});

export default RichTextarea;
