import { memo } from "react";
import { Form } from "react-bootstrap";

const Check = memo(function Check({
  className,
  label,
  name,
  value,
  onChange,
  disabled,
}) {
  const handleChange = (e) => {
    onChange({
      target: {
        name,
        value: e.target.checked,
      },
    });
  };

  return (
    <Form.Check
      className={className}
      label={label}
      name={name}
      checked={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
});

export default Check;
