import { memo } from "react";
import { Form } from "react-bootstrap";
import { isNullOrWhiteSpace } from "../utils/helpers";

const NumberInput = memo(function NumberInput({
  className,
  label,
  name,
  value,
  onChange,
  placeholder,
  disabled,
  min,
  max,
  errorMessages,
}) {
  const handleChange = (e) => {
    if (typeof onChange === "function") {
      const value = !isNullOrWhiteSpace(e.target.value)
        ? Number(e.target.value)
        : null;

      onChange({ target: { name, value } });
    }
  };

  const isInvalid = Array.isArray(errorMessages) && errorMessages.length > 0;

  return (
    <Form.Group className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="number"
        name={name}
        value={value ?? ""}
        onChange={handleChange}
        min={min}
        max={max}
        placeholder={placeholder}
        disabled={disabled}
        isInvalid={isInvalid}
      />
      <Form.Control.Feedback type="invalid">
        {isInvalid && errorMessages[0]}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

export default NumberInput;
