import { memo } from "react";
import { Button, ButtonGroup, Card } from "react-bootstrap";
import "./TournamentGame.css";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";

const TournamentGame = memo(function TournamentGame({
  id,
  gameInfo,
  onItemMove,
  disabled,
}) {
  const GameMoveButtons = !disabled && typeof onItemMove === "function" && (
    <ButtonGroup className="buttons">
      <Button
        className="button-right align-items-center"
        variant="secondary"
        onClick={() => onItemMove(-1)}
      >
        <CaretLeftFill />
      </Button>

      <Button
        className="button-right align-items-center"
        variant="secondary"
        onClick={() => onItemMove(1)}
      >
        <CaretRightFill />
      </Button>
    </ButtonGroup>
  );

  if (!gameInfo) {
    return (
      <Card className="tournament-game unknown">
        <div className="game">
          <div className="info">
            <div className="text-truncate text-muted text-center">
              <small>{id}</small>
            </div>
          </div>

          {GameMoveButtons}
        </div>
      </Card>
    );
  }

  const { thumbnail, game_name, vendor } = gameInfo;

  return (
    <Card className="tournament-game" title={id}>
      <div className="game">
        <div className="thumbnail">
          <img src={thumbnail} alt={game_name} />
        </div>

        <div className="info">
          <div className="d-grid">
            <div className="text-truncate">{game_name}</div>
            <div className="text-truncate text-muted">
              <small>{vendor}</small>
            </div>
          </div>
        </div>

        {GameMoveButtons}
      </div>
    </Card>
  );
});

export default TournamentGame;
