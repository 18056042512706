export class modelValidator {
  /**
   * Create a new model validator.
   * @param {any} model - The model to be validated.
   */
  constructor(model) {
    const schema = {};
    this._configureSchema(schema, model);

    this._model = model;
    this._schema = schema;
  }

  /**
   * @abstract
   * Configure validation schema.
   * @param {Record<string, Array<(value: any) => Array<string>>>} schema - The schema to be configured.
   * @param {any} model - The model to be validated.
   */
  _configureSchema(schema, model) {}

  /**
   * @private
   * Get model value by path.
   * @param {string} path - The path to the value to be retrieved.
   * @returns .
   */
  _getValue(path) {
    return path.split(".").reduce((acc, k) => acc[k], this._model);
  }

  /**
   * @private
   * Clone validation errors.
   * @param {Record<string, Array<string>>} validationErrors - Validation errors.
   * @returns Clone.
   */
  _cloneValidationErrors(validationErrors) {
    return validationErrors ? { ...validationErrors } : {};
  }

  /**
   * @private
   * Prune validation errors by prefix.
   * @param {Record<string, Array<string>>} errors - The errors to be pruned.
   * @param {string} prefix - The prefix to be pruned.
   */
  _pruneValidationErrors(errors, prefix) {
    for (let key in errors) {
      if (key === prefix || key.startsWith(`${prefix}.`)) {
        delete errors[key];
      }
    }
  }

  /**
   * @public
   * Validate model.
   * @param {string} key - The key to be validated. If missing, all keys are validated.
   * @param {*} previousValidationErrors - Previous validation errors.
   * @returns Validation errors.
   */
  validate(key, previousValidationErrors) {
    const validationErrors = this._cloneValidationErrors(
      previousValidationErrors
    );

    this._pruneValidationErrors(validationErrors, key);

    for (let schemaKey in this._schema) {
      if (!key || key === schemaKey || schemaKey.startsWith(`${key}.`)) {
        const errorMessages = [];
        const value = this._getValue(schemaKey);

        for (const validationFn of this._schema[schemaKey]) {
          const errorMessage = validationFn(value);
          if (errorMessage) {
            errorMessages.push(errorMessage);
          }
        }

        if (errorMessages.length > 0) {
          validationErrors[schemaKey] = errorMessages;
        }
      }
    }

    return validationErrors;
  }
}
