import { useState, memo } from "react";
import { Table, Form, Button, Modal, InputGroup } from "react-bootstrap";
import { isNullOrEmpty } from "../utils/helpers";
import { XLg } from "react-bootstrap-icons";

const BonusPicker = memo(function ({ bonuses, onSelect, onClose, disabled }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleSelect = (item) => {
    if (typeof onSelect !== "function") return;

    onSelect(item);
  };

  const handleClose = () => {
    if (typeof onClose !== "function") return;

    onClose();
  };

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  const filteredBonuses =
    (!isNullOrEmpty(searchTerm)
      ? bonuses?.filter(
          (item) =>
            item.code.toLowerCase().includes(lowerCaseSearchTerm) ||
            item.name.toLowerCase().includes(lowerCaseSearchTerm)
        )
      : bonuses) || [];

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Bonus</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className="mb-3">
          <InputGroup>
            <Form.Control
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search..."
            />
            <Button
              variant="outline-secondary"
              className="d-flex align-items-center p-2"
              onClick={handleClearSearch}
            >
              <XLg />
            </Button>
          </InputGroup>
        </Form>

        <Table striped bordered>
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {filteredBonuses.length === 0 ? (
              <tr>
                <td colSpan="3">
                  {bonuses?.length > 0
                    ? "No bonuses found"
                    : "No bonuses available"}
                </td>
              </tr>
            ) : (
              filteredBonuses.map((item, index) => (
                <tr key={index}>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>
                    {!disabled && (
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleSelect(item)}
                      >
                        Select
                      </Button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default BonusPicker;
