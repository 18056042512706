import React, { useState, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./App.css";
import RunningPage from "./pages/Running";
import ScheduledPage from "./pages/Scheduled";
import CompletedPage from "./pages/Completed";
import DraftPage from "./pages/Draft";
import CreateTournamentPage from "./pages/CreateTournament";
import TournamentPage from "./pages/Tournament";
import UnsentBonusListPage from "./pages/UnsentBonusList";
import NavigationBar from "./components/NavigationBar";
import Toast from "./components/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import CanceledPage from "./pages/Canceled";

const App = () => {
  const [toasts, setToasts] = useState([]);

  const navBarRef = useRef(null);

  const shell = {
    /**
     * Reference to the navigation bar component.
     */
    navBarRef,

    /**
     * Reference to toast state set function.
     */
    setToasts,

    /**
     * Set page title.
     * @param {string} title - Page title
     */
    setTitle: (title) => {
      document.title = title || "Tournaments Admin Console";
    },
  };

  return (
    <div id="app">
      <BrowserRouter>
        <NavigationBar ref={navBarRef} />
        <Container id="view" fluid>
          <Routes>
            <Route path="/" element={<RunningPage {...shell} />} />
            <Route path="/scheduled" element={<ScheduledPage {...shell} />} />
            <Route path="/completed" element={<CompletedPage {...shell} />} />
            <Route path="/draft" element={<DraftPage {...shell} />} />
            <Route path="/canceled" element={<CanceledPage {...shell} />} />

            <Route
              path="/create"
              element={<CreateTournamentPage {...shell} />}
            />
            <Route
              path="/tournament/:id"
              element={<TournamentPage {...shell} />}
            />
            <Route
              path="/unsent-bonus-list"
              element={<UnsentBonusListPage {...shell} />}
            />
          </Routes>
        </Container>
      </BrowserRouter>

      <ToastContainer position="bottom-end" className="position-fixed m-3">
        {
          toasts
            .map((toast, index) => (
              <Toast
                header={toast.header}
                text={toast.text}
                onClose={() =>
                  setToasts((prev) => prev.filter((t) => t !== toast))
                }
                key={index}
              />
            ))
            .reverse() /* first toast will be displayed in the bottom-right corner */
        }
      </ToastContainer>
    </div>
  );
};

export default App;
