import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
  useCallback,
} from "react";
import { getUnsentBonusCountAsync } from "../utils/api";
import { Link, NavLink } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown, Badge } from "react-bootstrap";
import {
  CheckCircle,
  Clock,
  FileEarmarkPlus,
  FileEarmarkText,
  Gift,
  PersonCircle,
  PlayCircle,
  XCircle,
} from "react-bootstrap-icons";
import { logOutAsync, getUser } from "../utils/auth";
import NavigationBarSeparator from "./NavigationBarSeparator";

const NavigationBar = forwardRef((_, ref) => {
  const user = getUser();

  const [unsentBonusCount, setUnsentBonusCount] = useState(0);

  const handleLogOut = () => {
    logOutAsync();
  };

  const refreshUnsentBonusesAsync = useCallback(async (ct) => {
    try {
      const count = await getUnsentBonusCountAsync(ct);
      setUnsentBonusCount(count);
    } catch (error) {
      if (ct?.aborted) return;
      setUnsentBonusCount("!");
    }
  }, []);

  useImperativeHandle(ref, () => ({
    /**
     * Refreshes the unsent bonus count.
     * @param {AbortSignal} ct Cancelation token.
     */
    refreshUnsentBonusesAsync,
  }));

  useEffect(() => {
    const cts = new AbortController();
    const task = () => refreshUnsentBonusesAsync(cts.signal);
    const interval = setInterval(task, 60000);
    task();

    return () => {
      cts.abort();
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UserDropdownTitle = (
    <React.Fragment>
      <PersonCircle />
      {user.displayName}
    </React.Fragment>
  );

  const UnsentBonusBadge = (typeof unsentBonusCount !== "number" ||
    unsentBonusCount > 0) && (
    <Badge className="translate-middle" bg="danger" pill>
      {unsentBonusCount}
    </Badge>
  );

  return (
    <Navbar id="main-navbar" sticky="top" expand="xl" className="border-bottom">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          Tournaments
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-navbar-nav" />

        <Navbar.Collapse id="main-navbar-nav">
          <Nav className="me-auto">
            <NavLink to="/" className="nav-link">
              <PlayCircle />
              Running
            </NavLink>
            <NavLink to="/scheduled" className="nav-link">
              <Clock />
              Scheduled
            </NavLink>
            <NavLink to="/completed" className="nav-link">
              <CheckCircle />
              Completed
            </NavLink>
            <NavLink to="/draft" className="nav-link">
              <FileEarmarkText />
              Draft
            </NavLink>
            <NavLink to="/canceled" className="nav-link">
              <XCircle />
              Canceled
            </NavLink>
            <NavLink to="/unsent-bonus-list" className="nav-link">
              <Gift />
              Unsent bonuses {UnsentBonusBadge}
            </NavLink>
          </Nav>

          <Nav>
            <NavLink to="/create" className={"nav-link"}>
              <FileEarmarkPlus />
              Create tournament
            </NavLink>
          </Nav>

          <Nav>
            <NavigationBarSeparator />
          </Nav>

          <Nav>
            <NavDropdown title={UserDropdownTitle} align={{ lg: "end" }}>
              <NavDropdown.Item href={user.accountPageUrl} target="_blank">
                Account settings
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogOut}>
                Sign out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
});

export default NavigationBar;
