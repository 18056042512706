import React, { useEffect, useState } from "react";
import { PlayCircle } from "react-bootstrap-icons";
import TournamentCardGrid from "../../components/TournamentCardGrid";
import { getRunningTournamentsAsync } from "../../utils/api";
import { Alert, Button } from "react-bootstrap";

const RunningPage = ({ setTitle }) => {
  const [refresh, setRefresh] = useState(0);

  const [state, setState] = useState({
    loading: true,
    tournaments: [],
    error: null,
  });

  setTitle("Running tournaments");

  const fetchData = async (ct) => {
    try {
      const data = await getRunningTournamentsAsync(ct);
      setState({
        loading: false,
        tournaments: data,
        error: null,
      });
    } catch (error) {
      if (ct?.aborted) return;

      setState((prev) => ({
        ...prev,
        loading: false,
        tournaments: [],
        error: error,
      }));
    }
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));

    const cts = new AbortController();
    fetchData(cts.signal);

    return () => {
      cts.abort();
    };
  }, [refresh]);

  return (
    <div className="running-page">
      <div className="d-flex border-bottom mb-3">
        <div className="page-title flex-grow-1 fs-5">
          <PlayCircle />
          Running tournaments
        </div>

        <div className="d-flex align-items-center">
          <Button
            variant="outline-secondary"
            className="ml-2 float-right"
            size="sm"
            onClick={() => setRefresh(refresh + 1)}
          >
            Refresh
          </Button>
        </div>
      </div>

      {state.error ? (
        <Alert variant="danger">Error: {state.error.message}</Alert>
      ) : (
        <TournamentCardGrid
          loading={state.loading}
          tournaments={state.tournaments}
        />
      )}
    </div>
  );
};

export default RunningPage;
