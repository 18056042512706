import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import "./TournamentCard.css";
import {
  BadgeAdFill,
  DoorOpenFill,
  EyeFill,
  GearFill,
  PlayFill,
  StopFill,
  Stopwatch,
  TrophyFill,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import blankImage from "../images/blank.png";
import moment from "moment";
import {
  getWinCriteriaDisplayText,
  getTournamentOptInConditionDisplayText,
  getTournamentScoreDriverDisplayText,
} from "../utils/helpers";
import constants from "../utils/constants";

const TournamentCard = ({ tournament }) => {
  const start = moment.utc(tournament.time_configuration?.start).local();
  const end = moment.utc(tournament.time_configuration?.end).local();
  const duration = moment.duration(end.diff(start));
  const time = moment.duration(Math.max(0, end.diff(moment())));

  const scoreDriver = getTournamentScoreDriverDisplayText(
    tournament.score_driver
  );

  const winCriteria = getWinCriteriaDisplayText(tournament.win_criteria);
  const optInCondition = getTournamentOptInConditionDisplayText(
    tournament.opt_in_condition
  );

  return (
    <Link to={`/tournament/${tournament.id}`} className="tournament-card-link">
      <Card className="tournament-card">
        <Card.Img
          variant="top"
          src={tournament.hero_image_mobile ?? blankImage}
        />

        <Card.Body>
          <Card.Title className="text-truncate">{tournament.title}</Card.Title>
          <Card.Text className="text-truncate">
            {tournament.short_description}
          </Card.Text>

          <ListGroup className="list-group-flush metrics small">
            <ListGroup.Item>
              <GearFill />
              {scoreDriver}
            </ListGroup.Item>
            <ListGroup.Item>
              <PlayFill />
              {start.format(constants.DateTimeStringFormat)}
            </ListGroup.Item>
            <ListGroup.Item>
              <StopFill />
              {end.format(constants.DateTimeStringFormat)},{" "}
              {duration.humanize()}
            </ListGroup.Item>
            <ListGroup.Item>
              <Stopwatch />
              {time.as("milliseconds") === 0 ? "Ended" : time.humanize()}
            </ListGroup.Item>
            <ListGroup.Item>
              <GearFill />
              {winCriteria}
            </ListGroup.Item>
            <ListGroup.Item>
              <DoorOpenFill />
              {optInCondition}
            </ListGroup.Item>
            <ListGroup.Item>
              <BadgeAdFill />
              {tournament.is_promoted ? "Promoted" : "-"}
            </ListGroup.Item>
            <ListGroup.Item>
              <EyeFill />
              {tournament.is_hidden ? "Hidden" : "Visible"}
            </ListGroup.Item>
            <ListGroup.Item>
              <TrophyFill />
              {tournament.leaderboard_size} players
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default TournamentCard;
