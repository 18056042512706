import { useCallback, useEffect, useMemo, useState } from "react";
import { getUnsentBonusesAsync, markBonusAsSentAsync } from "../../utils/api";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import moment from "moment";
import { formatNumber } from "../../utils/helpers";
import React from "react";
import constants from "../../utils/constants";
import { BoxArrowUpRight, Gift, XLg } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const UnsentBonusList = ({ navBarRef, setTitle, setToasts }) => {
  const [refresh, setRefresh] = useState(0);

  const [state, setState] = useState({
    loading: true,
    bonuses: [],
    searchString: "",
    error: null,
  });

  setTitle(`Unsent bonuses - ${state.bonuses.length}`);

  const handleSearch = useCallback((e) => {
    setState((prev) => ({ ...prev, searchString: e.target.value }));
  }, []);

  const handleClearSearch = useCallback(() => {
    setState((prev) => ({ ...prev, searchString: "" }));
  }, []);

  const fetchDataAsync = async (ct) => {
    try {
      const bonuses = await getUnsentBonusesAsync(ct);
      setState((prev) => ({
        ...prev,
        loading: false,
        bonuses: bonuses,
        error: null,
      }));
    } catch (error) {
      if (ct?.aborted) return;

      setState((prev) => ({
        ...prev,
        loading: false,
        bonuses: [],
        error: error,
      }));
    }
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));

    const cts = new AbortController();
    fetchDataAsync(cts.signal);
    navBarRef.current?.refreshUnsentBonusesAsync(cts.signal);

    return () => {
      cts.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const bonuses = useMemo(
    () =>
      state.bonuses
        .filter((entry) =>
          state.searchString
            ? entry.user_id.toString().includes(state.searchString) ||
              entry.bonus_code.includes(state.searchString)
            : true
        )
        .sort((a, b) => a.created - b.created)
        .reverse(),
    [state.bonuses, state.searchString]
  );

  const sendBonusAsync = async (bonus) => {
    setState((prev) => ({
      ...prev,
      bonuses: prev.bonuses.map((b) => {
        if (b === bonus) {
          b.sending = true;
        }

        return b;
      }),
    }));

    try {
      await markBonusAsSentAsync(bonus);

      setRefresh((prev) => prev + 1);

      setToasts([{ header: "Success", text: "Bonus sent" }]);
    } catch (error) {
      setState((prev) => ({
        ...prev,
        bonuses: prev.bonuses.map((b) => {
          if (b === bonus) {
            delete b.sending;
          }

          return b;
        }),
      }));

      setToasts([{ header: "Error", text: "Sending bonus failed" }]);
    }
  };

  const stats = useMemo(() => {
    const stats = { total: 0 };

    state.bonuses.forEach(() => {
      stats.total++;
    });

    return stats;
  }, [state.bonuses]);

  const statsStr = [`${stats.total} entries`];

  return (
    <div className="unsent-bonus-list">
      <div className="d-flex border-bottom mb-3">
        <div className="page-title flex-grow-1 fs-5">
          <Gift />
          Unsent bonuses
        </div>
      </div>

      <div className="mb-2">
        <small>{statsStr.join(", ")}</small>
      </div>

      <Row className="align-items-center mb-3">
        <Col lg={4} md={6} xs={8}>
          <InputGroup>
            <Form.Control
              type="text"
              value={state.searchString}
              onChange={handleSearch}
              placeholder="Search..."
            />
            <Button
              variant="outline-secondary"
              className="d-flex align-items-center p-2"
              onClick={handleClearSearch}
            >
              <XLg />
            </Button>
          </InputGroup>
        </Col>

        <Col className="text-end">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => setRefresh((prev) => prev + 1)}
          >
            Refresh
          </Button>
        </Col>
      </Row>

      <Table className="mt-3" bordered responsive hover>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Tournament ID</th>
            <th>User ID</th>
            <th>Score</th>
            <th>Bonus Code</th>
            <th>Created</th>
            <th>Failures</th>
            <th>Send</th>
          </tr>
        </thead>

        <tbody>
          {state.loading ? (
            <tr>
              <td colSpan="8">Loading...</td>
            </tr>
          ) : state.error ? (
            <tr>
              <td colSpan="8">Error: {state.error.message}</td>
            </tr>
          ) : bonuses.length > 0 ? (
            bonuses.map((entry) => {
              const key = [
                entry.tournament_id,
                entry.rank,
                entry.user_id,
                entry.bonus_code,
              ].join("-");

              const created = moment.utc(entry.created).local();

              const createdStr = created.format(constants.DateTimeStringFormat);

              const createdFromNowStr = created.fromNow();

              return (
                <tr key={key}>
                  <th scope="row">{entry.rank}</th>
                  <td>
                    <Link
                      to={`/tournament/${entry.tournament_id}`}
                      className="link-primary d-flex align-items-center"
                      target="_blank"
                    >
                      {entry.tournament_id}
                      <BoxArrowUpRight className="ms-2" />
                    </Link>
                  </td>
                  <td>{entry.user_id}</td>
                  <td>{formatNumber(Math.round(entry.total_score))}</td>
                  <td>{entry.bonus_code}</td>
                  <td>
                    {createdStr}
                    <small className="d-block text-muted">
                      {createdFromNowStr}
                    </small>
                  </td>
                  <td>{entry.send_failures}</td>
                  <td>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => sendBonusAsync(entry)}
                      disabled={entry.sending}
                    >
                      Send
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8">No entries</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default UnsentBonusList;
