import { memo } from "react";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.css";

const DateTimePicker = memo(function DateTimePicker({
  className,
  label,
  name,
  value,
  onChange,
  dateFormat,
  minDate,
  maxDate,
  showTimeSelect,
  timeIntervals,
  timeFormat,
  inline,
  withPortal,
  startDate,
  endDate,
  disabled,
  errorMessages,
}) {
  const onDateChange = (date) => {
    if (typeof onChange === "function")
      onChange({
        target: { name, value: date },
      });
  };

  timeFormat ??= "HH:mm";
  dateFormat ??= showTimeSelect ? `yyyy-MM-dd ${timeFormat}` : "yyyy-MM-dd";

  const isInvalid = Array.isArray(errorMessages) && errorMessages.length > 0;

  const pickerClassNames = ["form-control"];
  if (isInvalid) {
    pickerClassNames.push("is-invalid");
  }

  return (
    <Form.Group className={className}>
      <Form.Label>{label}</Form.Label>
      <ReactDatePicker
        className={pickerClassNames.join(" ")}
        selected={value}
        onChange={(date) => onDateChange(date)}
        dateFormat={dateFormat}
        calendarStartDay={1}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect={showTimeSelect}
        timeCaption="Time"
        timeIntervals={timeIntervals}
        timeFormat={timeFormat}
        inline={inline}
        withPortal={withPortal}
        fixedHeight
        shouldCloseOnSelect={false}
        openToDate={value}
        disabled={disabled}
      />
    </Form.Group>
  );
});

export default DateTimePicker;
