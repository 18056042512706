import { memo, useCallback, useMemo } from "react";
import DateTimePicker from "./DateTimePicker";

const ISOStringDateTimePicker = memo(function ISOStringDateTimePicker({
  className,
  label,
  name,
  value: stringValue,
  onChange,
  dateFormat,
  minDate: stringMinDate,
  maxDate: stringMaxDate,
  showTimeSelect,
  timeIntervals,
  timeFormat,
  inline,
  withPortal,
  startDate: stringStartDate,
  endDate: stringEndDate,
  disabled,
  errorMessages,
}) {
  const value = useMemo(
    () => stringValue && new Date(stringValue),
    [stringValue]
  );

  const startDate = useMemo(
    () => stringStartDate && new Date(stringStartDate),
    [stringStartDate]
  );

  const endDate = useMemo(
    () => stringEndDate && new Date(stringEndDate),
    [stringEndDate]
  );

  const minDate = useMemo(
    () => stringMinDate && new Date(stringMinDate),
    [stringMinDate]
  );

  const maxDate = useMemo(
    () => stringMaxDate && new Date(stringMaxDate),
    [stringMaxDate]
  );

  const onInterceptChange = useCallback(
    ({ target: { name, value } }) => {
      if (typeof onChange === "function")
        onChange({
          target: { name, value: value?.toISOString() },
        });
    },
    [onChange]
  );

  return (
    <DateTimePicker
      className={className}
      label={label}
      name={name}
      value={value}
      onChange={onInterceptChange}
      dateFormat={dateFormat}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      showTimeSelect={showTimeSelect}
      timeIntervals={timeIntervals}
      timeFormat={timeFormat}
      inline={inline}
      withPortal={withPortal}
      disabled={disabled}
      errorMessages={errorMessages}
    />
  );
});

export default ISOStringDateTimePicker;
