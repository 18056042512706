import { memo } from "react";
import { Form } from "react-bootstrap";

const Textarea = memo(function Textarea({
  className,
  label,
  name,
  value,
  onChange,
  placeholder,
  disabled,
  height,
  errorMessages,
}) {
  const style = {};
  if (height) style.height = height;

  const length = value?.length ?? 0;
  const lines = value?.split("\n").length ?? 0;

  const isInvalid = Array.isArray(errorMessages) && errorMessages.length > 0;

  return (
    <Form.Group className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="textarea"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        isInvalid={isInvalid}
        style={style}
      />
      <Form.Text muted>
        {length} length, {lines} lines
      </Form.Text>
      <Form.Control.Feedback type="invalid">
        {isInvalid && errorMessages[0]}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

export default Textarea;
