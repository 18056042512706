import { useCallback, useEffect, useRef, useState } from "react";
import {
  exportLeaderboardAsync,
  getLeaderboardAsync,
  getPlayerRankAsync,
} from "../../utils/api";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Navbar,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import moment from "moment";
import Pagination from "../../components/Pagination";
import {
  downloadBlob,
  formatNumber,
  getTournamentBonusDisplayText,
  isNullOrWhiteSpace,
} from "../../utils/helpers";
import React from "react";
import constants from "../../utils/constants";
import { XLg } from "react-bootstrap-icons";

const page_size = 100;
const search_delay = 1000;

const Leaderboard = ({ tournament }) => {
  const searchRef = useRef(null);
  const searchTimerRef = useRef(null);
  const ctsRef = useRef(null);

  const [refresh, setRefresh] = useState(0);

  const [state, setState] = useState({
    loading: true,
    exporting: false,
    leaderboard: {},
    page: 0,
    error: null,
  });

  const handleExport = useCallback(async () => {
    setState((prev) => ({ ...prev, exporting: true }));

    try {
      const blob = await exportLeaderboardAsync(tournament.id);
      const now = new Date();
      const filename = `${tournament.id}_leaderboard_${now.toISOString()}.csv`;

      downloadBlob(blob, filename);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }

    setState((prev) => ({ ...prev, exporting: false }));
  }, [tournament.id]);

  const handleSearch = (e) => {
    ctsRef.current?.abort();
    ctsRef.current = new AbortController();

    const searchString = e.target.value;
    if (searchTimerRef.current) clearTimeout(searchTimerRef.current);

    searchTimerRef.current = setTimeout(() => {
      setState((prev) => ({ ...prev, loading: true, error: null }));

      if (!isNullOrWhiteSpace(searchString)) {
        search(searchString, ctsRef.current.signal);
      } else {
        fetchData(ctsRef.current.signal);
      }
    }, search_delay);
  };

  const handleClearSearch = () => {
    if (isNullOrWhiteSpace(searchRef.current.value)) return;

    searchRef.current.value = "";
    searchRef.current.focus();

    handleSearch({ target: { value: "" } });
  };

  const handleFetchData = () => {
    setState((prev) => ({ ...prev, loading: true }));

    searchRef.current.value = "";

    ctsRef.current?.abort();
    ctsRef.current = new AbortController();

    fetchData(ctsRef.current.signal);
  };

  const handlePageClick = useCallback((page) => {
    setState((prev) => ({ ...prev, page }));
  }, []);

  const search = async (searchString, ct) => {
    try {
      const rank = await getPlayerRankAsync(tournament.id, searchString, ct);

      setState((prev) => ({
        ...prev,
        loading: false,
        leaderboard: { size: 1, rows: [{ ...rank.row, rank: rank.rank }] },
        error: null,
      }));
    } catch (error) {
      if (ct?.aborted) return;

      setState((prev) => ({
        ...prev,
        loading: false,
        leaderboard: {},
        error: error,
      }));
    }
  };

  const fetchData = async (ct) => {
    try {
      const leaderboard = await getLeaderboardAsync(
        tournament.id,
        page_size,
        state.page * page_size,
        ct
      );

      setState((prev) => ({
        ...prev,
        loading: false,
        leaderboard,
        error: null,
      }));
    } catch (error) {
      if (ct?.aborted) return;

      setState((prev) => ({
        ...prev,
        loading: false,
        leaderboard: {},
        error: error,
      }));
    }
  };

  useEffect(() => {
    handleFetchData();

    return () => {
      ctsRef.current?.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, state.page]);

  const maxPage = state.leaderboard.size
    ? Math.floor(state.leaderboard.size / page_size)
    : 0;

  const prizes = [];
  tournament.tiers.forEach((tier) => {
    const bonusCode = tier.bonus.code;
    const bonusDisplayText = getTournamentBonusDisplayText(tier.bonus);
    for (let i = tier.rank_min; i <= tier.rank_max; i++) {
      prizes[i] ??= [];
      prizes[i].push({
        code: bonusCode,
        displayText: bonusDisplayText,
      });
    }
  });

  return (
    <div className="leaderboard">
      <div className="mb-2">
        <small>
          {state.leaderboard.size ?? 0} rows, {page_size} rows per page,{" "}
          {maxPage + 1} pages
        </small>
      </div>

      <Row className="align-items-center mb-2">
        <Col lg={4} md={6} xs={8}>
          <InputGroup>
            <Form.Control
              type="text"
              onChange={handleSearch}
              ref={searchRef}
              placeholder="Search..."
            />
            <Button
              variant="outline-secondary"
              className="d-flex align-items-center p-2"
              onClick={handleClearSearch}
            >
              <XLg />
            </Button>
          </InputGroup>
        </Col>

        <Col className="text-end">
          <Button
            variant="outline-success"
            className="me-2"
            size="sm"
            onClick={handleExport}
            disabled={state.exporting}
          >
            {state.exporting ? (
              <React.Fragment>
                <Spinner size="sm" className="me-2" /> exporting...
              </React.Fragment>
            ) : (
              "Export"
            )}
          </Button>

          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => setRefresh((prev) => prev + 1)}
          >
            Refresh
          </Button>
        </Col>
      </Row>

      <Table className="mt-3" bordered responsive hover>
        <thead>
          <tr>
            <th rowSpan={2}>Rank</th>
            <th colSpan={2}>User</th>
            <th rowSpan={2}>Score</th>
            <th rowSpan={2}>Computed</th>
            <th colSpan={2}>Prize</th>
          </tr>
          <tr>
            <th>ID</th>
            <th>Username</th>
            <th>Code</th>
            <th>Name</th>
          </tr>
        </thead>

        <tbody>
          {state.loading ? (
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          ) : state.error ? (
            <tr>
              <td colSpan="7">Error: {state.error.message}</td>
            </tr>
          ) : (
            state.leaderboard.rows?.map((row) => {
              const computed = moment.utc(row.computed).local();
              const computedStr = computed.format(
                constants.DateTimeStringFormat
              );

              const computedFromNowStr = computed.fromNow();

              const prize = prizes[row.rank];
              const prizeCodes = [];
              const prizeDisplayTexts = [];
              if (prize) {
                prize.forEach((p) => {
                  prizeCodes.push(p.code);
                  prizeDisplayTexts.push(p.displayText);
                });
              }

              return (
                <tr key={row.rank}>
                  <th scope="row">{row.rank}</th>
                  <td>{row.user_id}</td>
                  <td>{row.user_name}</td>
                  <td>{formatNumber(Math.round(row.total_score))}</td>
                  <td>
                    {computedStr}
                    <small className="d-block text-muted">
                      {computedFromNowStr}
                    </small>
                  </td>
                  <td>{prizeCodes.length ? prizeCodes.join(", ") : "-"}</td>
                  <td>
                    {prizeDisplayTexts.length
                      ? prizeDisplayTexts.join(", ")
                      : "-"}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>

      {maxPage > 1 && (
        <Navbar className="fixed-bottom bg-body-tertiary border-top p-3">
          <Container fluid>
            <Pagination
              className="m-0"
              page={state.page}
              maxPage={maxPage}
              margin={3}
              onPageClick={handlePageClick}
            />
          </Container>
        </Navbar>
      )}
    </div>
  );
};

export default Leaderboard;
