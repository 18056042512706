import { useCallback, useEffect, useMemo, useState } from "react";
import { getBonusOutboxAsync, getUsersByIdAsync } from "../../utils/api";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import moment from "moment";
import { formatNumber, isNullOrUndefined } from "../../utils/helpers";
import React from "react";
import constants from "../../utils/constants";
import Check from "../../components/Check";
import { XLg } from "react-bootstrap-icons";

const BonusOutbox = ({ tournament }) => {
  const [refresh, setRefresh] = useState(0);

  const [state, setState] = useState({
    loading: true,
    outbox: [],
    showOnlyFailed: false,
    searchString: "",
    error: null,
  });

  const handleShowOnlyFailed = useCallback((e) => {
    setState((prev) => ({ ...prev, showOnlyFailed: e.target.value }));
  }, []);

  const handleSearch = useCallback((e) => {
    setState((prev) => ({ ...prev, searchString: e.target.value }));
  }, []);

  const handleClearSearch = useCallback(() => {
    setState((prev) => ({ ...prev, searchString: "" }));
  }, []);

  const fetchData = async (ct) => {
    try {
      const outbox = await getBonusOutboxAsync(tournament.id, ct);

      const userIds = outbox.map((b) => b.sent_by);
      const users = await getUsersByIdAsync(userIds, ct);
      const usersLookup = users.reduce((acc, user) => {
        acc[user.id] = user.displayName;
        return acc;
      }, {});

      outbox.forEach((b) => {
        b.sent_by = usersLookup[b.sent_by] || b.sent_by;
      });

      setState((prev) => ({
        ...prev,
        loading: false,
        outbox,
        error: null,
      }));
    } catch (error) {
      if (ct?.aborted) return;

      setState((prev) => ({
        ...prev,
        loading: false,
        outbox: [],
        error: error,
      }));
    }
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));

    const cts = new AbortController();
    fetchData(cts.signal);

    return () => {
      cts.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const outbox = useMemo(
    () =>
      state.outbox
        .filter((entry) =>
          state.showOnlyFailed
            ? !isNullOrUndefined(entry.sent) && entry.send_failures > 0
            : true
        )
        .filter((entry) =>
          state.searchString
            ? entry.user_id.toString().includes(state.searchString) ||
              entry.bonus_code.includes(state.searchString)
            : true
        )
        .sort((a, b) => a.rank - b.rank),
    [state.outbox, state.showOnlyFailed, state.searchString]
  );

  const stats = useMemo(() => {
    const stats = { total: 0, sent: 0, pending: 0, failed: 0 };

    state.outbox.forEach((entry) => {
      stats.total++;
      if (!isNullOrUndefined(entry.sent)) {
        stats.sent++;
      } else if (entry.send_failures > 0) {
        stats.failed++;
      } else {
        stats.pending++;
      }
    });

    return stats;
  }, [state.outbox]);

  const statsStr = [
    `${stats.total} entries`,
    `${stats.sent} sent`,
    `${stats.pending} pending`,
    `${stats.failed} failed`,
  ];

  return (
    <div className="bonus-outbox">
      <div className="mb-2">
        <small>{statsStr.join(", ")}</small>
      </div>

      <Row className="align-items-center mb-3">
        <Col lg={4} md={6} xs={8}>
          <InputGroup>
            <Form.Control
              type="text"
              value={state.searchString}
              onChange={handleSearch}
              placeholder="Search..."
            />
            <Button
              variant="outline-secondary"
              className="d-flex align-items-center p-2"
              onClick={handleClearSearch}
            >
              <XLg />
            </Button>
          </InputGroup>
        </Col>

        <Col className="text-end">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => setRefresh((prev) => prev + 1)}
          >
            Refresh
          </Button>
        </Col>
      </Row>

      <Check
        label="Show only failed to send"
        value={state.showOnlyFailed}
        onChange={handleShowOnlyFailed}
      />

      <Table className="mt-3" bordered responsive hover>
        <thead>
          <tr>
            <th>Rank</th>
            <th>User ID</th>
            <th>Score</th>
            <th>Bonus Code</th>
            <th>Created</th>
            <th>Sent</th>
            <th>Failures</th>
            <th>Sent by</th>
          </tr>
        </thead>

        <tbody>
          {state.loading ? (
            <tr>
              <td colSpan="8">Loading...</td>
            </tr>
          ) : state.error ? (
            <tr>
              <td colSpan="8">Error: {state.error.message}</td>
            </tr>
          ) : outbox.length > 0 ? (
            outbox.map((entry) => {
              const key = [
                entry.tournament_id,
                entry.rank,
                entry.user_id,
                entry.bonus_code,
              ].join("-");

              const created = moment.utc(entry.created).local();

              const createdStr = created.format(constants.DateTimeStringFormat);

              const createdFromNowStr = created.fromNow();

              const sent = !isNullOrUndefined(entry.sent)
                ? moment.utc(entry.sent).local()
                : null;

              const sentStr =
                sent?.format(constants.DateTimeStringFormat) ?? "-";

              const sentFromNowStr = sent?.fromNow() ?? "-";

              return (
                <tr key={key}>
                  <th scope="row">{entry.rank}</th>
                  <td>{entry.user_id}</td>
                  <td>{formatNumber(Math.round(entry.total_score))}</td>
                  <td>{entry.bonus_code}</td>
                  <td>
                    {createdStr}
                    <small className="d-block text-muted">
                      {createdFromNowStr}
                    </small>
                  </td>
                  <td>
                    {sentStr}
                    <small className="d-block text-muted">
                      {sentFromNowStr}
                    </small>
                  </td>
                  <td>{entry.send_failures}</td>
                  <td>{entry.sent_by}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8">No entries</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default BonusOutbox;
