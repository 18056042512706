import React, { memo } from "react";
import { Nav } from "react-bootstrap";

const Tabs = memo(function Tabs({ tabs }) {
  const hash = window.location.hash;
  const key = hash.startsWith("#/") ? hash.substring(2) : "";

  const activeTab =
    (key.length > 0
      ? tabs.find((tab) => !tab.disabled && tab.key === key)
      : null) ?? tabs.find((tab) => !tab.disabled);

  const content = activeTab?.content;

  return (
    <React.Fragment>
      <Nav variant="tabs" defaultActiveKey={activeTab?.key}>
        {tabs?.map((tab) => (
          <Nav.Item key={tab.key}>
            <Nav.Link
              href={`#/${tab.key}`}
              eventKey={tab.key}
              active={tab.key === activeTab?.key}
              disabled={tab.disabled}
            >
              {tab.title}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <div className="mt-3">{content}</div>
    </React.Fragment>
  );
});

export default Tabs;
