import { buildQueryString, buildUrl } from "./helpers";
import constants from "./constants";
import { getUser, getUserToken } from "./auth";

/**
 * Build fully qualified API URL.
 * @param  {...any} parts - URL parts to concatenate.
 * @returns .
 */
const buildApiAbsoluteUrl = (...parts) =>
  buildUrl(window.EnvConstants.REACT_APP_API_URI, ...parts);

/**
 * Perform api call.
 * @param {*} opts - Options.
 * @returns .
 */
const call = async (opts) => {
  const url =
    buildApiAbsoluteUrl(opts.endpoint) +
    (opts.query
      ? typeof opts.query === "string"
        ? opts.query
        : buildQueryString(opts.query)
      : "");

  const method = opts.method || "GET";

  const headers = {
    "Content-Type": "application/json",
  };

  const user = getUser();
  if (user.authenticated) headers["Authorization"] = "Bearer " + getUserToken();

  if (opts.headers) Object.assign(headers, opts.headers);

  const body = opts.body;

  const resp = await fetch(url, {
    method,
    headers,
    body,
    signal: opts.ct,
  });

  if (resp.ok) return opts.blob ? resp.blob() : resp.json();

  throw new Error(`${resp.status} - ${resp.statusText}`);
};

/**
 * Get running tournaments.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getRunningTournamentsAsync = async (ct) =>
  await call({
    endpoint: `/api/tournaments/running`,
    method: "GET",
    ct,
  });

/**
 * Get scheduled tournaments.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getScheduledTournamentsAsync = async (ct) =>
  await call({
    endpoint: `/api/tournaments/scheduled`,
    method: "GET",
    ct,
  });

/**
 * Get completed tournaments.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getCompletedTournamentsAsync = async (ct) =>
  await call({
    endpoint: `/api/tournaments/completed`,
    method: "GET",
    ct,
  });

/**
 * Get tournaments in status.
 * @param {string} tournamentStatus - Tournament status.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getTournamentsInStatusAsync = async (tournamentStatus, ct) =>
  await call({
    endpoint: `/api/tournaments/in-status/${tournamentStatus}`,
    method: "GET",
    ct,
  });

/**
 * Get draft tournaments.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getDraftTournamentsAsync = async (ct) =>
  await getTournamentsInStatusAsync(constants.Tournament.Status.Draft, ct);

/**
 * Get game feed infos.
 * @param {number} take - The number of game feeds to retrieve (optional).
 * @param {number} skip - The number of game feeds to skip (optional).
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getGameFeedInfosAsync = async (take, skip, ct) =>
  await call({
    endpoint: `/api/game-feeds/infos`,
    query: { take, skip },
    method: "GET",
    ct,
  });

/**
 * Search game feed infos.
 * @param {string} searchString - The search string.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const searchGameFeedInfosAsync = async (searchString, ct) =>
  await call({
    endpoint: `/api/game-feeds/infos/search`,
    query: { q: searchString },
    method: "GET",
    ct,
  });

/**
 * Get multiple game feed infos by id.
 * @param {Array<number>} casinoGameIds - An array of casino game ids.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getMultipleGameFeedInfosByIdAsync = async (casinoGameIds, ct) =>
  await call({
    endpoint: `/api/game-feeds/infos`,
    method: "POST",
    body: JSON.stringify(casinoGameIds),
    ct,
  });

/**
 * Create a new tournament.
 * @param {*} tournament - Tournament model.
 * @param {*} ct - Cancelation token.
 * @returns Tournament ID.
 */
export const createTournamentAsync = async (tournament, ct) =>
  await call({
    endpoint: `/api/tournaments`,
    method: "PUT",
    body: JSON.stringify(tournament),
    ct,
  });

/**
 * Get tournament.
 * @param {number} id - Tournament ID.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getTournamentAsync = async (id, ct) =>
  await call({
    endpoint: `/api/tournaments/${id}`,
    method: "GET",
    ct,
  });

/**
 * Update tournament.
 * @param {*} tournament - Tournament model.
 * @param {*} ct - Cancelation token.
 * @returns Tournament ID.
 */
export const updateTournamentAsync = async (tournament, ct) =>
  await call({
    endpoint: `/api/tournaments/${tournament.id}`,
    method: "POST",
    body: JSON.stringify(tournament),
    ct,
  });

/**
 * Cancel tournament.
 * @param {number} id - Tournament ID.
 * @param {*} ct - Cancelation token.
 * @returns Tournament ID.
 */
export const cancelTournamentAsync = async (id, ct) =>
  await call({
    endpoint: `/api/tournaments/${id}/cancel`,
    method: "PUT",
    ct,
  });

/**
 * Assign player to tournament.
 * @param {number} tournamentId - Tournament ID.
 * @param {number} userId - User ID.
 * @param {*} ct - Cancelation token.
 * @returns .
 */
export const assignPlayerAsync = async (tournamentId, userId, ct) =>
  await call({
    endpoint: `/api/tournaments/${tournamentId}/players/${userId}`,
    method: "PUT",
    ct,
  });

/**
 * Get tournament leaderboard.
 * @param {number} tournamentId - Tournament ID.
 * @param {number} take - The number of leaderboard rows to retrieve (optional).
 * @param {number} skip - The number of leaderboard rows to skip (optional).
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getLeaderboardAsync = async (tournamentId, take, skip, ct) =>
  await call({
    endpoint: `/api/leaderboard/${tournamentId}`,
    query: { take, skip },
    method: "GET",
    ct,
  });

/**
 * Get bonuses.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getBonusesAsync = async (ct) =>
  await call({
    endpoint: `/api/bonuses`,
    method: "GET",
    ct,
  });

/**
 * Get tournament bonus outbox.
 * @param {number} tournamentId - Tournament ID.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getBonusOutboxAsync = async (tournamentId, ct) =>
  await call({
    endpoint: `/api/bonus-outbox/tournament/${tournamentId}`,
    method: "GET",
    ct,
  });

/**
 * Get unsent bonuses.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getUnsentBonusesAsync = async (ct) =>
  await call({
    endpoint: `/api/bonus-outbox/failed`,
    method: "GET",
    ct,
  });

/**
 * Get count unsent bonuses.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getUnsentBonusCountAsync = async (ct) =>
  await call({
    endpoint: `/api/bonus-outbox/failed/count`,
    method: "GET",
    ct,
  });

/**
 * Mark bonus as sent.
 * @param {*} bonusOutbox - Bonus outbox model.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const markBonusAsSentAsync = async (bonusOutbox, ct) =>
  await call({
    endpoint: `/api/bonus-outbox/sent`,
    method: "PUT",
    body: JSON.stringify(bonusOutbox),
    ct,
  });

/**
 * Get player rank.
 * @param {number} tournamentId - Tournament ID.
 * @param {string} userId - User ID.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const getPlayerRankAsync = async (tournamentId, userId, ct) =>
  await call({
    endpoint: `/api/leaderboard/${tournamentId}/player/${userId}/rank`,
    method: "GET",
    ct,
  });

/**
 * Export leaderboard.
 * @param {number} tournamentId - Tournament ID.
 * @param {AbortSignal} ct - Cancelation token.
 * @returns .
 */
export const exportLeaderboardAsync = async (tournamentId, ct) =>
  await call({
    endpoint: `/api/export/leaderboard/${tournamentId}`,
    method: "GET",
    headers: {
      "Content-Type": "text/csv",
    },
    blob: true,
    ct,
  });

/**
 * Retrieves users for the specified user IDs.
 * @param {Array<string>} ids - An array of user IDs for which to retrieve information.
 * @param {AbortController} ct - Cancelation token.
 * @returns .
 */
export const getUsersByIdAsync = async (ids, ct) =>
  await call({
    endpoint: `/api/admin-users`,
    method: "POST",
    body: JSON.stringify(ids),
    ct,
  });

/**
 * Upsert user information.
 * @param {AbortController} ct - Cancelation token.
 * @returns .
 */
export const upsertUserAsync = async (ct) =>
  await call({
    endpoint: `/api/admin-users`,
    method: "PUT",
    ct,
  });
