import { memo } from "react";
import { Form } from "react-bootstrap";

const DayPicker = memo(function DayPicker({
  className,
  label,
  name,
  value,
  onChange,
  disabled,
  errorMessages,
}) {
  const [sunday, monday, tuesday, wednesday, thursday, friday, saturday] =
    value;

  const onValueChange = ({ target }) => {
    if (typeof onChange !== "function") return;

    const day = target.dataset.day;
    if (day < 0 || day > 6) return;

    const data = [
      !!value[0],
      !!value[1],
      !!value[2],
      !!value[3],
      !!value[4],
      !!value[5],
      !!value[6],
    ];

    const checked = (data[day] = target.checked);
    onChange({ target: { name, day, value: data, checked } });
  };

  return (
    <Form.Group className={className}>
      <Form.Label>{label}</Form.Label>

      {Array.isArray(errorMessages) && errorMessages.length > 0 && (
        <div className="d-none form-control is-invalid" />
      )}

      <Form.Check
        label="Monday"
        checked={monday}
        onChange={onValueChange}
        data-day={1}
        disabled={disabled}
      />

      <Form.Check
        label="Tuesday"
        checked={tuesday}
        onChange={onValueChange}
        data-day={2}
        disabled={disabled}
      />

      <Form.Check
        label="Wednesday"
        checked={wednesday}
        onChange={onValueChange}
        data-day={3}
        disabled={disabled}
      />

      <Form.Check
        label="Thursday"
        checked={thursday}
        onChange={onValueChange}
        data-day={4}
        disabled={disabled}
      />

      <Form.Check
        label="Friday"
        checked={friday}
        onChange={onValueChange}
        data-day={5}
        disabled={disabled}
      />

      <Form.Check
        label="Saturday"
        checked={saturday}
        onChange={onValueChange}
        data-day={6}
        disabled={disabled}
      />

      <Form.Check
        label="Sunday"
        checked={sunday}
        onChange={onValueChange}
        data-day={0}
        disabled={disabled}
      />
    </Form.Group>
  );
});

export default DayPicker;
