import { memo } from "react";
import { Form } from "react-bootstrap";
import { isNullOrWhiteSpace } from "../utils/helpers";

const Select = memo(function Select({
  className,
  label,
  name,
  value,
  valueIsNumber,
  onChange,
  options,
  disabled,
  errorMessages,
}) {
  const handleChange = (e) => {
    if (typeof onChange === "function") {
      const value = valueIsNumber
        ? !isNullOrWhiteSpace(e.target.value)
          ? Number(e.target.value)
          : null
        : e.target.value;

      onChange({ target: { name, value } });
    }
  };

  const isInvalid = Array.isArray(errorMessages) && errorMessages.length > 0;

  return (
    <Form.Group className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        isInvalid={isInvalid}
      >
        {options?.map((option) => (
          <option key={option.key} value={option.value}>
            {option.displayText}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {isInvalid && errorMessages[0]}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

export default Select;
