import React from "react";
import { Card, ListGroup, Placeholder } from "react-bootstrap";
import "./TournamentCard.css";
import blankImage from "../images/blank.png";

const TournamentCardPlaceholder = () => {
  return (
    <Card className="tournament-card loading">
      <Card.Img variant="top" src={blankImage} />
      <Card.Body>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={12} />
        </Placeholder>

        <ListGroup className="list-group-flush metrics small">
          <ListItemPlaceholder length={8} />
          <ListItemPlaceholder length={8} />
          <ListItemPlaceholder length={4} />
          <ListItemPlaceholder length={4} />
          <ListItemPlaceholder length={6} />
          <ListItemPlaceholder length={3} />
          <ListItemPlaceholder length={3} />
          <ListItemPlaceholder length={5} />
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

const ListItemPlaceholder = ({ length }) => (
  <Placeholder as={ListGroup.Item} animation="glow">
    <Placeholder xs={1} /> <Placeholder xs={length} />
  </Placeholder>
);

export default TournamentCardPlaceholder;
