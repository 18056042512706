import { DragDropList } from "./DragDropList";
import { Col } from "react-bootstrap";
import TournamentGame from "./TournamentGame";

export const DragDropGames = ({
  gameIds,
  gameInfos,
  onGamesChange,
  accessor,
  disabled,
}) => {
  const handleItemMove = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex > gameIds.length - 1) return;

    const updatedListItems = [...gameIds];
    const [movedItem] = updatedListItems.splice(fromIndex, 1);
    updatedListItems.splice(toIndex, 0, movedItem);

    onGamesChange(updatedListItems);
  };

  return (
    gameIds && (
      <DragDropList
        items={gameIds.map((id, index) => (
          <Col key={id} className="gb-3">
            <TournamentGame
              id={id}
              gameInfo={gameInfos?.[id]}
              onItemMove={(value) => handleItemMove(index, index + value)}
              disabled={disabled}
            />
          </Col>
        ))}
        accessor={accessor}
        onItemMove={handleItemMove}
        disabled={disabled}
      />
    )
  );
};
