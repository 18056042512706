import React, { useCallback, useState, useEffect } from "react";
import { FileEarmarkPlus, Check2 } from "react-bootstrap-icons";
import Tournament from "../../components/Tournament";
import {
  createTournamentModel,
  performTournamentModelChange,
  prepareTournamentModelForApi,
} from "../../utils/models";
import { Alert, Button, Container, Navbar, Spinner } from "react-bootstrap";
import { createTournamentAsync } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { tournamentUpdateValidator } from "../../validations/tournamentUpdateValidator";
import { tournamentSaveValidator } from "../../validations/tournamentSaveValidator";

const incomplete_tournament_storage_key = "incomplete_new_tournament";
const persist_delay = 1000;

const CreateTournamentPage = ({ setTitle, setToasts }) => {
  const navigate = useNavigate();

  const [tournament, setTournament] = useState(createTournamentModel());

  const [state, setState] = useState({ loading: false, error: null });
  const [validationErrors, setValidationErrors] = useState({});
  const [persisted, setPersisted] = useState(true);

  setTitle("Create tournament");

  useEffect(() => {
    const incompleteTournamentJson = localStorage.getItem(
      incomplete_tournament_storage_key
    );

    if (!incompleteTournamentJson) return;

    const loadFromStorage = window.confirm(
      "You have an unfinished tournament. Would you like to continue?"
    );

    if (loadFromStorage) {
      const incompleteTournament = JSON.parse(incompleteTournamentJson);
      setTournament(incompleteTournament);
      return;
    }

    setTournament(createTournamentModel());
    localStorage.removeItem(incomplete_tournament_storage_key);
  }, []);

  useEffect(() => {
    if (persisted) {
      return;
    }

    const timeout = setTimeout(() => {
      const tournamentJson = JSON.stringify(tournament);
      localStorage.setItem(incomplete_tournament_storage_key, tournamentJson);
      setPersisted(true);
    }, persist_delay);

    return () => clearTimeout(timeout);
  }, [tournament, persisted]);

  const handleTournamentChange = useCallback((e) => {
    setTournament((prev) => {
      const tournament = { ...prev };
      performTournamentModelChange(tournament, e);

      const validator = new tournamentUpdateValidator(tournament);
      setValidationErrors((errors) =>
        validator.validate(e.target.name, errors)
      );

      setPersisted(false);
      return tournament;
    });
  }, []);

  const handleCreateTournament = () => {
    const validator = new tournamentSaveValidator(tournament);
    const errors = validator.validate();

    const toasts = [];
    for (let key in errors) {
      for (let error of errors[key]) {
        toasts.push({ header: "Error", text: error });
      }
    }

    if (toasts.length > 0) {
      setToasts(toasts);
      setValidationErrors(errors);
      return;
    }

    setState((prev) => ({ ...prev, loading: true, error: null }));
    prepareTournamentModelForApi(tournament);
    localStorage.setItem("incompleteNewTournament", "");
    createTournament(tournament);
  };

  const createTournament = async (tournament) => {
    try {
      const id = await createTournamentAsync(tournament);
      navigate(`/tournament/${id}`);
      setToasts([{ header: "Success", text: "Tournament created" }]);

      localStorage.removeItem(incomplete_tournament_storage_key);
    } catch (error) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: error,
      }));
    }
  };

  return (
    <React.Fragment>
      <div className="create-tournament-page" style={{ marginBottom: 200 }}>
        <div className="d-flex border-bottom mb-3">
          <div className="page-title flex-grow-1 fs-5">
            <FileEarmarkPlus />
            Create tournament
            {persisted && (
              <div className="float-end">
                <Check2 />
              </div>
            )}
          </div>
        </div>

        {state.error && <Alert variant="danger">{state.error.message}</Alert>}
        <Tournament
          tournament={tournament}
          onTournamentChange={handleTournamentChange}
          disabled={state.loading}
          errors={validationErrors}
        />
      </div>
      <Navbar className="fixed-bottom bg-body-tertiary border-top p-3">
        <Container fluid>
          <Button
            className="px-3"
            variant="success"
            size="sm"
            onClick={handleCreateTournament}
            disabled={state.loading}
          >
            Create
          </Button>

          {state.loading && <Spinner size="sm" />}
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default CreateTournamentPage;
