import { tournamentUpdateValidator } from "./tournamentUpdateValidator";
import { is } from "./is";
import constants from "../utils/constants";

export class tournamentSaveValidator extends tournamentUpdateValidator {
  _configureSchema(schema, tournament) {
    super._configureSchema(schema, tournament);

    if (
      tournament.score_driver ===
      constants.Tournament.ScoreDriver.GamingTransactions
    )
      schema["wagering_conditions"] = [
        is.array.ofSizeGreaterOrEqual(
          1,
          "Should be at least one wagering condition"
        ),
      ];

    if (
      tournament.score_driver ===
      constants.Tournament.ScoreDriver.BonusWinAmount
    )
      schema["bonus_win_amount_ingestion_configuration"] = [
        (config) =>
          Object.keys(config).length === 0 &&
          "Should be at least one bonus win amount ingestion configuration",
      ];

    schema["tiers"] = [
      is.array.ofSizeGreaterOrEqual(1, "Should be at least one tier"),
    ];

    tournament.wagering_conditions.forEach((_, i) => {
      const key = `wagering_conditions.${i}`;

      schema[`${key}.casino_game_ids`] = [
        is.array.ofSizeGreaterOrEqual(
          1,
          "Should be at least one game in condition"
        ),
      ];
    });
  }
}
