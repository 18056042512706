import { useMemo } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import ISOStringDateTimePicker from "./ISOStringDateTimePicker";
import DayPicker from "./DayPicker";

export const time_configuration_days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const TournamentTimeConfiguration = ({
  name,
  config,
  onConfigChange,
  className,
  disabled,
  errors,
}) => {
  const today = moment.utc().minute(0).second(0).millisecond(0);

  const start = useMemo(
    () => config.start && moment(config.start),
    [config.start]
  );

  const end = useMemo(() => config.end && moment(config.end), [config.end]);

  const approaching = useMemo(() => {
    if (!start) return null;

    const diff = start.diff(moment.utc());
    return diff > 0 ? moment.duration(diff) : null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(config), start]);

  const running = useMemo(() => {
    if (!start || !end) return null;

    const days = end.diff(start, "days");
    const startDay = start.day();
    const repetitions = Array(7).fill(0);

    for (let i = 0; i < days; i++) {
      const day = (startDay + i) % 7;
      if (config[time_configuration_days[day]]) repetitions[day]++;
    }

    return repetitions.reduce((a, b) => a + b, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(config), start, end]);

  return (
    <Card className={className}>
      <Card.Title className="p-3 mb-0 border-bottom">
        Time configuration
      </Card.Title>

      <Card.Body>
        <Row>
          <Col>
            <div className="mb-3">
              <ISOStringDateTimePicker
                label="Start"
                name={`${name}.start`}
                value={config.start}
                startDate={config.start}
                endDate={config.end}
                onChange={onConfigChange}
                showTimeSelect
                minDate={today}
                aria-describedby={`${name}_start_info`}
                disabled={disabled}
                errorMessages={errors?.[`${name}.start`]}
              />

              <Form.Text id={`${name}_start_info`} muted>
                approaching in {approaching?.humanize() || "..."}
              </Form.Text>
            </div>

            <div className="mb-3">
              <ISOStringDateTimePicker
                label="End"
                name={`${name}.end`}
                value={config.end}
                startDate={config.start}
                endDate={config.end}
                onChange={onConfigChange}
                showTimeSelect
                minDate={config.start}
                aria-describedby={`${name}_end_info`}
                disabled={disabled}
                errorMessages={errors?.[`${name}.end`]}
              />

              <Form.Text id={`${name}_end_info`} muted>
                {running || "0"} days to run
              </Form.Text>
            </div>
          </Col>

          <Col md={4}>
            <DayPicker
              name={`${name}.day`}
              value={time_configuration_days.map((day) => config[day])}
              onChange={onConfigChange}
              disabled={disabled}
              errorMessages={errors?.[`${name}.day`]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TournamentTimeConfiguration;
