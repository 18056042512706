import { memo } from "react";
import { Form } from "react-bootstrap";

const Input = memo(function Input({
  className,
  label,
  type,
  name,
  value,
  defaultValue,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  onPaste,
  placeholder,
  disabled,
  errorMessages,
}) {
  const isInvalid = Array.isArray(errorMessages) && errorMessages.length > 0;

  return (
    <Form.Group className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        placeholder={placeholder}
        disabled={disabled}
        isInvalid={isInvalid}
      />
      <Form.Control.Feedback type="invalid">
        {isInvalid && errorMessages[0]}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

export default Input;
