import { useDrag, useDrop } from "react-dnd";

export const DragDropItem = ({
  accessor,
  index,
  item,
  onItemMove,
  disabled,
}) => {
  const [, drag] = useDrag({
    type: accessor,
    item: { index },
    canDrag: () => !disabled,
  });

  const [, drop] = useDrop({
    accept: accessor,
    drop: (draggedItem) => {
      if (draggedItem.index === index) return;

      onItemMove(draggedItem.index, index);
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ cursor: disabled ? "not-allowed" : "move" }}
    >
      {item}
    </div>
  );
};
